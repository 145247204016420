<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="98vw" overlay-opacity="0.9" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<b>Bulk Activity Import</b>
				<v-spacer/>
				<v-btn v-if="collection_url" color="secondary" class="mr-2" @click="get_collection_url">Process New Collection<v-icon small class="ml-2">fas fa-circle-arrow-right</v-icon></v-btn>
				<v-btn color="secondary" @click="$emit('dialog_close')">Done<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
			</v-card-title>
			<v-card-text v-if="cdata.collection_url" class="pt-3" style="font-size:16px">
				<h3>“{{cdata.collection_title}}” <span style="font-weight:normal">{{cdata.n_activities}} activities</span> <v-btn x-small text color="primary" :href="cdata.collection_url" target="_blank">Show Original</v-btn></h3>
				<div class="d-flex mt-4 mb-3">
					<b class="pt-1 pr-3">Settings:</b>
					<div class="mr-3">
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.module_folders" hide-details row class="ma-0 pa-1"><span class="mr-2">Create folders for modules:</span>
							<v-radio label="Yes" value="on"></v-radio>
							<v-radio label="No" value="off"></v-radio>
						</v-radio-group></div>
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.skip_first_activity_of_module" hide-details row class="ma-0 pa-1"><span class="mr-2">Skip first item in each module:</span>
							<v-radio label="Yes" value="on"></v-radio>
							<v-radio label="No" value="off"></v-radio>
						</v-radio-group></div>
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.skip_first_module" hide-details row class="ma-0 pa-1"><span class="mr-2">Skip first module:</span>
							<v-radio label="On" value="on"></v-radio>
							<v-radio label="Off" value="off"></v-radio>
						</v-radio-group></div>
						<div class="k-bulk-activity-setting"><span class="mr-2">Attribution:</span>
							<v-text-field class="pb-2 pr-2" single-line dense hide-details v-model="settings.attribution"></v-text-field>
						</div>
					</div>
					<div>
						<div class="mb-2">
							<v-select v-model="settings.conversion_script" :items="conversion_script_select_options" label="Conversion Script" dense outlined hide-details></v-select>
						</div>
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.game_mode" hide-details row class="ma-0 pa-1"><span class="mr-2">Game Mode:</span>
							<v-radio label="On" value="on"></v-radio>
							<v-radio label="Off" value="off"></v-radio>
						</v-radio-group></div>
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.open_door" hide-details row class="ma-0 pa-1"><span class="mr-2">“Open-Door” Mode:</span>
							<v-radio label="On" value="on"></v-radio>
							<v-radio label="Off" value="off"></v-radio>
						</v-radio-group></div>
						<!-- <div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.gavs_corrections" hide-details row class="ma-0 pa-1"><span class="mr-2">Additional GAVS corrections:</span>
							<v-radio label="On" value="on"></v-radio>
							<v-radio label="Off" value="off"></v-radio>
						</v-radio-group></div> -->
						<div class="k-bulk-activity-setting" style="padding:3px 0"><v-radio-group v-model="settings.log" hide-details row class="ma-0 pa-1"><span class="mr-2">Console.log:</span>
							<v-radio label="On" value="on"></v-radio>
							<v-radio label="Off" value="off"></v-radio>
						</v-radio-group></div>
					</div>
				</div>
				<div v-if="cdata.n_activities>0">
					<div class="pb-2 mt-2 text-center" style="border-bottom:1px solid #ccc">
						<b>All Modules/Activities:</b>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="primary" @click="scrape_all_modules">Convert All</v-btn>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="primary" @click="add_all_modules_to_unit">Add All to Unit</v-btn>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="secondary" @click="export_activity_links">Export Links</v-btn>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="secondary" @click="all_modules_hp5_info">Activity H5P Summary</v-btn>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="secondary" @click="fetch_all_modules_contents">Fetch Activity Contents</v-btn>
						<v-btn x-small outlined class="k-tight-btn ml-2" color="secondary" @click="push_activity_contents">Push Activity Contents</v-btn>
					</div>
					<div v-for="(module, i) in cdata.modules" class="mt-2">
						<v-hover v-slot:default="{hover}"><div class="d-flex align-center" :class="(last_clicked_activity_index==i)?'yellow lighten-4':(hover?'grey lighten-3':'')">
							<div><b>Module {{i}}</b>: {{module.title}}</div>
							<v-spacer/>
							<CASEItemBtn v-for="(s) in module.case_alignments" :key="s.cfitem.identifier" :item="s.cfitem" btn_color="purple darken-2" @click="choose_module_standards(module, i)" x-small :outer_class="'mr-1'" />
							<v-btn x-small text class="k-tight-btn" color="purple darken-2" @click="choose_module_standards(module, i)"><v-icon small class="mr-1">fas fa-map</v-icon>Choose Standards</v-btn>
							<v-btn v-if="!module_scraped(module)&&!module_processing(module)" class="k-tight-btn" color="primary" x-small text @click="scrape_module_activities(module)">{{module_created(module)?'Re-':''}}Convert Activities</v-btn>
							<v-btn v-if="module_created(module)&&!module_added_to_cureum(module)&&!module_processing(module)" class="k-tight-btn" color="primary" x-small text @click="add_module_to_unit(module)">Add Activities to Unit</v-btn>
						</div></v-hover>
						<div v-if="module.activities.length>1" class="px-2 ml-4 my-1 d-flex grey lighten-3">
							<b style="font-size:12px; margin:-1px 8px 0 0;">Module “Activity Bank”:</b>
							<v-btn v-if="!module_processing(module)" class="k-tight-btn" color="primary" x-small text @click="create_module_bank(module, i)">{{ module.bank_activity_id?'Update':'Create' }} Activity Bank</v-btn>
							<v-btn class="k-tight-btn" v-show="module.bank_activity_id" color="amber darken-4" x-small text @click="preview_bank(module)"><v-icon small>fas fa-glasses</v-icon></v-btn>
							<v-btn v-if="module.bank_activity_id&&!module_bank_added_to_cureum(module)&&!module_processing(module)" class="k-tight-btn" color="primary" x-small text @click="add_module_bank_to_unit(module, i)">Add Bank Resource to Unit</v-btn>
							<v-spacer/>
						</div>
						<div v-for="(activity, j) in module.activities" class="ml-2"><v-hover v-slot:default="{hover}">
							<div :class="(last_clicked_activity_index==`${activity.module_index}_${activity.activity_index}`)?'yellow lighten-4':(hover?'grey lighten-3':'')" style="padding:1px 4px 1px 4px">
								<div class="d-flex align-start">
									<div v-if="activity.link_url" :class="hover?'grey lighten-5':''"><b class="text--orange">External link</b>: <a :href="activity.link_url" target="_blank">{{activity.title}}</a></div>
									<div v-else>
										<span v-visible="activity.created" class="amber darken-4 white--text" style="display:inline-block; width:40px; text-align:center; padding:0 2px 0px 2px; border-radius:3px;">{{activity.stars_available}}<v-icon x-small color="white">fas fa-star</v-icon></span>
										<v-icon small :class="activity_icon_class(activity)" class="mr-2">{{activity_icon(activity)}}</v-icon>
										<b :class="activity_label_class(activity)">Activity</b>: {{activity.title}} 
									</div>
									<v-spacer/>
									<div style="white-space:nowrap" class="d-flex align-center">
										<v-btn class="k-tight-btn" v-show="activity.errors.length" color="red darken-2" x-small text @click="show_activity_errors(activity)">Errors</v-btn>
										<v-btn class="k-tight-btn k-nocaps-btn" v-show="activity.h5ps.length" color="lime darken-3" x-small text @click="show_activity_h5ps(activity)">H5Ps [{{activity.converted_h5p_n}}/{{activity.unconverted_h5p_n}}/{{activity.unconverted_h5p_audio_n}}]</v-btn>
										<CASEItemBtn v-for="(s) in activity.case_alignments" :key="s.cfitem.identifier" :item="s.cfitem" btn_color="purple darken-2" show_delete_icon @click="choose_activity_standards(activity, j)" @delete="remove_activity_standard(activity, j, s)" x-small :outer_class="'mr-1'" />
										<v-btn x-small text class="k-tight-btn" color="purple darken-2" @click="choose_activity_standards(activity, j)"><v-icon small>fas fa-map</v-icon></v-btn>
										<v-btn x-small text class="k-tight-btn" color="purple darken-2" @click="copy_standards_from_previous_activity(activity, j)" :disabled="j==0"><v-icon small>fas fa-arrow-up</v-icon></v-btn>
										<v-btn class="k-tight-btn" v-show="activity.href" color="secondary" x-small text @click="show_original_activity(activity)">Original</v-btn>
										<v-btn class="k-tight-btn" v-show="activity.href&&!activity.processing" color="primary" x-small text @click="scrape_activity(activity)">{{activity.created?'Re-':''}}Convert</v-btn>
										<!-- <v-btn class="k-tight-btn" v-show="activity.href&&!activity.scraped&&!activity.processing" color="primary" x-small text @click="scrape_activity(activity)">{{activity.created?'Re-':''}}Convert</v-btn> -->
										<!-- <v-btn class="k-tight-btn" v-show="activity.scraped&&!activity.processing" color="primary" x-small text @click="scrape_activity(activity)">Re-Scrape</v-btn>
										<v-btn class="k-tight-btn" v-show="activity.scraped&&!activity.processing" color="primary" x-small text @click="create_sparkl_activity(activity)">{{activity.created?'Re-':''}}Create</v-btn> -->
										<v-btn class="k-tight-btn" v-show="activity.created&&!activity.processing" color="amber darken-4" x-small text @click="preview_activity(activity)"><v-icon small>fas fa-glasses</v-icon></v-btn>
										<v-btn class="k-tight-btn" v-show="activity.created&&!activity.added_to_cureum&&!activity.processing" color="green darken-2" x-small text @click="add_to_unit(activity)">Add to Unit</v-btn>
									</div>
								</div>
								<ul v-if="activity.h5ps.length>0" v-show="activity.h5ps_showing" class="k-activity-import-activity-errors mb-1 ml-4">
									<li v-for="(h5p) in activity.h5ps" class="lime--text text--darken-4" style="font-size:14px; line-height:16px;" v-html="h5p"></li>
								</ul>
								<ul v-if="activity.errors.length>0" v-show="activity.errors_showing" class="k-activity-import-activity-errors mb-1 ml-4">
									<li v-for="(error) in activity.errors" class="red--text text--darken-2" style="font-size:14px; line-height:16px;" v-html="error"></li>
								</ul>
							</div>
						</v-hover></div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import './from_sparkl/auto-blank-utilities.js'
import './from_sparkl/Exercise.js'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	components: { CASEItemBtn,  },
	props: {
		// req: { type: String, required: true },
		unit: { type: Object, required: false, default() { return null } },
		collection: { type: Object, required: false, default() { return null } },
	},
	data() { return {
		dialog_open: true,
		base_href: 'https://gavirtual.instructure.com',
		params: {},
		collection_url: '',
		cdata: {},
		last_saved_cdata: {},
		settings: {},
		scrape_activity_queue: [],
		add_to_unit_queue: [],
		// use a fake email we can track as the editor of the sparkl activity and the cureum resource -- keep this in sync with bulk_create_sparkl_activity.php
		bulk_create_editor_email: 'bulkcreate@commongoodlt.com',

		conversion_script_select_options: [
			{value:'single_ir', text:'Single Interactive Reading Exercise'},
			{value:'single_bc', text:'Single Freeform (Basic Content) Exercise'},
			{value:'gavs_elementary', text:'GAVS Elementary'},
		],

		last_clicked_activity_index: -1,
		
		/////////////////////////////////////////////
		// any properties of modules and activities that we want to be reactive must be defined here
		default_module: {
			title: '',
			module_folder_id: '',
			bank_activity_id: 0,
			activities: [],
			case_alignments: [],
		},

		default_activity: {
			href: '',
			link_url: '',
			title: '',
			errors: [],
			h5ps: [],
			fatal_error: false,
			module_index: 0,
			activity_index: 0,
			exercise_ids: [],

			created: false,
			added_to_cureum: false,

			case_alignments: [],

			converted_h5p_n: 0,
			unconverted_h5p_n: 0,
			unconverted_h5p_audio_n: 0,
			activity_id: 0,
			stars_available: 0,
			resource_id: '',

			// the following properties, plus everything else not in the list above, are stripped out before saving to lst
			scraped: false,
			processing: false,
			errors_showing: false,
			h5ps_showing: false,
		},
		/////////////////////////////////////////////

		// choose animals that are easy for little kids to identify
		animal_icons: ['ant', 'bat', 'bee', 'bird', 'cat', 'cow', 'crab', 'deer', 'dog', 'dragon', 'elephant', 'fish-fins', 'frog', 'hippo', 'horse', 'monkey', 'mouse-field', 'pig', 'rabbit', 'spider', 'squirrel', 't-rex', 'turtle', 'unicorn'],
	}},
	computed: {
		...mapState(['user_info', 'site_config',]),
		...mapGetters([]),
		// collection_url() { return this.cdata.collection_url },
		// collection_url: {
		// 	get() { return this.$store.state.lst.bulk_activity_import_data.current_collection_url },
		// 	set(val) { 
		// 		let o = object_copy(this.$store.state.lst.bulk_activity_import_data)
		// 		o.current_collection_url = val
		// 		this.$store.commit('lst_set', ['bulk_activity_import_data', o])
		// 	}
		// },
		modules() { return this.cdata?.modules ?? [] },
		// cdata() { 
		// 	if (!this.collection_url || !this.$store.state.lst.bulk_activity_import_data.collection_data[this.collection_url]) return {}
		// 	return this.$store.state.lst.bulk_activity_import_data.collection_data[this.collection_url] 
		// },

		sparkl_origin() { 
			return this.collection.sparkl_origin_override || this.site_config.sparkl_origin
		},
	},
	watch: {
		settings: { deep:true, handler() {
			// console.log('settings watcher handler')
			this.$store.commit('lst_set', ['bulk_activity_settings', this.settings])
		}},

		cdata: { deep:true, handler() {
			// save to server if changed; debounce so we don't do this too frequently
			// establish the debounce fn if necessary
			if (empty(this.debounce_cdata_fn)) {
				this.debounce_cdata_fn = U.debounce(function() {
					// if (empty(this.collection_url)) return
					let cc = object_copy(this.cdata)
					// strip everything not part of default_module and default_activity (plus a few things that are) out before saving
					if (cc.modules) for (let module of cc.modules) {
						for (let prop in module) if (this.default_module[prop] === undefined) delete module[prop]
						for (let activity of module.activities) {
							for (let prop in activity) if (this.default_activity[prop] === undefined) delete activity[prop]
							delete activity.scraped
							delete activity.processing
							delete activity.errors_showing
							delete activity.h5ps_showing
						}
					}

					// stash in store
					this.$store.state['cdata_' + this.unit.lp_unit_id] = cc

					cc = JSON.stringify(cc)
					if (cc != this.last_saved_cdata) {
						let payload = {
							user_id: this.user_info.user_id,
							lp_unit_id: this.unit.lp_unit_id, 
							bulk_import_collection_data: cc,
						}
						U.ajax('save_bulk_import_collection_data', payload, result=>{
							if ((result?.status ?? '') != 'ok') {
								this.$alert('Error in save_bulk_import_collection_data')
							} else {
								// if unit still has bulk_import_collection_data, delete it and save the lp
								if (!empty(this.unit.bulk_import_collection_data)) {
									console.log('DELETING bulk_import_collection_data FROM UNIT')
									this.$store.commit('set', [this.unit, 'bulk_import_collection_data', null])
									this.save_collection()
								}
							}
						})
						this.last_saved_cdata = cc
					}
				}, 1000)
			}
			// call the debounce fn
			this.debounce_cdata_fn()
		}}
	},
	created() {
		vapp.bulk_activity_import_component = this
	},
	mounted() {
		// if we stashed cdata in the store, retrieve it (particularly useful when debugging)
		if (!empty(this.$store.state['cdata_' + this.unit.lp_unit_id])) {
			this.initialize_cdata(this.$store.state['cdata_' + this.unit.lp_unit_id])
		
		// else load from file
		} else {
			U.loading_start()
			U.ajax('retrieve_bulk_import_collection_data', {user_id: this.user_info.user_id, lp_unit_id: this.unit.lp_unit_id}, result=>{
				U.loading_stop()
				// error in service
				if ((result?.status ?? '') != 'ok') {
					this.$alert('Error in retrieve_bulk_import_collection_data')

				// if we received data, initialize it
				} else if (!empty(result.bulk_import_collection_data)) {
					this.initialize_cdata(JSON.parse(result.bulk_import_collection_data))
				
				// else if we have data in the unit, initialize based on it
				// this shouldn't be relevant anymore, but keep just in case
				} else if (!empty(this.unit.bulk_import_collection_data)) {
					console.log('INITIALIZING CDATA FROM UNIT.bulk_import_collection_data')
					this.initialize_cdata(object_copy(this.unit.bulk_import_collection_data))
				
				// else initialize with empty data
				} else {
					this.initialize_cdata({})
				}
			})
		}
	},
	methods: {
		initialize_cdata(cdata) {
			this.last_saved_cdata = JSON.stringify(cdata)
			if (!empty(cdata.collection_url)) this.collection_url = cdata.collection_url

			if (!cdata.case_framework_identifier) cdata.case_framework_identifier = ''
			if (!cdata.last_case_item_identifier) cdata.last_case_item_identifier = ''

			// clean up some things about activity records
			if (cdata.modules) for (let i = 0; i < cdata.modules.length; ++i) {
				// recreate modules/activities to make sure reactivity works properly
				// note: have to use object_copy as well as extend because of arrays in the default values
				cdata.modules[i] = object_copy($.extend({}, this.default_module, cdata.modules[i]))
				
				// reduce size of case data
				for (let case_alignment of cdata.modules[i].case_alignments) case_alignment.cfitem = new CASE_Item(case_alignment.cfitem)

				if (cdata.modules[i].activities) for (let j = 0; j < cdata.modules[i].activities.length; ++j) {
					cdata.modules[i].activities[j] = object_copy($.extend({}, this.default_activity, cdata.modules[i].activities[j]))
					let activity = cdata.modules[i].activities[j]

					// reduce size of case data
					for (let case_alignment of activity.case_alignments) case_alignment.cfitem = new CASE_Item(case_alignment.cfitem)

					activity.errors_showing = false

					// the activity can't be processing at the start
					activity.processing = false

					// we don't save html, so it can't be scraped
					activity.scraped = false
					// if (empty(activity.html)) activity.scraped = false
				}
				if (cdata.modules[i].activities.length == 0) {
					cdata.modules.splice(i, 1)
					--i
				}
			}
			// console.log('initial cdata', cdata)
			this.cdata = cdata

			if (empty(this.collection_url)) {
				this.get_collection_url()
			}

			this.settings = $.extend({
				module_folders: 'on',
				skip_first_activity_of_module: 'on',
				skip_first_module: 'off',
				conversion_script: 'single_ir',
				// num_parts: 'multiple',	// multiple, single
				// first_part_type: 'ir',	// ir, cr, bc
				// last_part_type: 'cr',
				// other_part_types: 'bc',
				gavs_corrections: 'on',	// on, off
				game_mode: 'off',	// on, off
				open_door: 'on', // on, off
				attribution: 'gavs',	// open-ended string
				log: 'off',	// on, off
			}, this.$store.state.lst.bulk_activity_settings)

		},

		activity_icon(activity) {
			if (activity.processing) return 'fas fa-asterisk'
			if (activity.added_to_cureum) return 'fas fa-circle-check'
			if (activity.created) return 'fas fa-ellipsis'
			if (activity.errors.length > 0) return 'fas fa-circle-exclamation'
			if (activity.scraped) return 'fas fa-icicles'
			return 'fas fa-asterisk'
		},
		activity_icon_class(activity) {
			let s = 'text--darken-2'
			if (activity.processing) s += ' fa-spin blue--text'
			else if (activity.added_to_cureum) s += ' green--text'
			else if (activity.created) s += ' purple--text'
			else if (activity.scraped) s += ' orange--text'
			else if (activity.errors.length > 0) s += ' red--text'
			else s += ' grey--text'
			return s
		},
		activity_label_class(activity) {
			return this.activity_icon_class(activity)
		},

		show_activity_errors(activity) {
			activity.errors_showing = !activity.errors_showing
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
		},

		show_activity_h5ps(activity) {
			activity.h5ps_showing = !activity.h5ps_showing
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
		},

		show_original_activity(activity) {
			let url = this.base_href + activity.href
			window.open(url)
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
		},

		preview_bank(module) {
			if (module.bank_activity_id == 0) {
				this.$alert('Bank not previewable, because we don’t have an activity_id')
			} else {
				let url = this.sparkl_origin + '/' + module.bank_activity_id
				window.open(url)
			}
		},

		preview_activity(activity) {
			if (activity.activity_id == 0) {
				this.$alert('Activity not previewable, because we don’t have an activity_id')
			} else {
				let url = this.sparkl_origin + '/' + activity.activity_id
				window.open(url)
			}
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
		},

		module_scraped(module) {
			// return true if all activities in the module have been scraped
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (!activity.scraped) return false
			}
			return true
		},

		module_processing(module) {
			// return true if any of the activities in the module are currently processing
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (activity.processing) return true
			}
			return false
		},

		module_created(module) {
			// return true if all activities in the module have been created
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (!activity.created) return false
			}
			return true
		},

		module_added_to_cureum(module) {
			// return true if all activities in the module have been added_to_cureum
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (!activity.added_to_cureum) return false
			}
			return true
		},
		
		module_bank_added_to_cureum(module) {
			// return true if all activities in the module have been added_to_cureum
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (!activity.added_to_cureum) return false
			}
			return true
		},
		
		calculate_num_blanks(activity, html) {
			let blankable_words = U.auto_blank_process_html(html)
			if (blankable_words.length == 0) {
				activity.errors.push('Possible error: no blankable words')
				return 0
			}
			return U.auto_blank_num_blanks(blankable_words.length, 'fewer')
		},

		choose_module_standards(module, module_index) {
			this.last_clicked_activity_index = module_index
			
			let data = { framework_identifier: '', item_identifier: '' }

			if (this.cdata.case_framework_identifier) {
				data.framework_identifier = this.cdata.case_framework_identifier
			}

			// add current module standards as selected items
			if (module.case_alignments.length > 0) {
				data.selected_items = []
				for (let case_alignment of module.case_alignments) data.selected_items.push(case_alignment.cfitem.identifier)
				// in this case set case_framework_identifier to the first case_alignment
				data.framework_identifier = module.case_alignments[0].framework_identifier
			// else open to the last-selected item
			} else if (this.cdata.last_case_item_identifier) {
				data.item_identifier = this.cdata.last_case_item_identifier
			}

			// set hide_fn to hide the standards chooser if/when the bulk editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($('.k-bulk-activity-setting').length == 0) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// convert aligned_item.cfitem to CASE_Item structure, to dump some data we don't need (e.g. supplementalInfo, notes)
						aligned_item.cfitem = new CASE_Item(aligned_item.cfitem)

						// if we already have this item aligned, remove the case_alignment
						let i = module.case_alignments.findIndex(o=>o.cfitem.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							module.case_alignments.splice(i, 1)

							// also remove from all module activities
							for (let activity of module.activities) {
								let i = activity.case_alignments.findIndex(x=>x.cfitem.identifier==aligned_item.cfitem.identifier)
								if (i > -1) activity.case_alignments.splice(i, 1)
							}

							// re-initialize the chooser, showing the framework for the item we removed
							this.choose_module_standards(module)

						} else {
							// Add the case_alignment, which will include the cfitem and the framework_identifier (this is the format that Sparkl wants)
							module.case_alignments.push(aligned_item)

							// also add to all module activities
							for (let activity of module.activities) {
								if (!activity.case_alignments.find(x=>x.cfitem.identifier==aligned_item.cfitem.identifier)) {
									activity.case_alignments.push(aligned_item)
								}
							}

							// re-initialize the chooser, showing the framework for the item we added
							this.choose_module_standards(module)

							// set cdata.case_framework_identifier and last_case_item_identifier
							this.cdata.case_framework_identifier = aligned_item.framework_identifier
							this.cdata.last_case_item_identifier = aligned_item.cfitem.identifier
						}
					})
				})
			})
		},

		choose_activity_standards(activity, activity_index) {
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
			
			let data = { framework_identifier: '', item_identifier: '' }

			if (this.cdata.case_framework_identifier) {
				data.framework_identifier = this.cdata.case_framework_identifier
			}

			// add current activity standards as selected items
			if (activity.case_alignments.length > 0) {
				data.selected_items = []
				for (let case_alignment of activity.case_alignments) data.selected_items.push(case_alignment.cfitem.identifier)
				// in this case set case_framework_identifier to the first case_alignment
				data.framework_identifier = activity.case_alignments[0].framework_identifier
			
			// else open to the last-selected item
			} else if (this.cdata.last_case_item_identifier) {
				data.item_identifier = this.cdata.last_case_item_identifier
			}

			// set hide_fn to hide the standards chooser if/when the bulk editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($('.k-bulk-activity-setting').length == 0) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// convert aligned_item.cfitem to CASE_Item structure, to dump some data we don't need (e.g. supplementalInfo, notes)
						aligned_item.cfitem = new CASE_Item(aligned_item.cfitem)
						
						// if we already have this item aligned, remove the case_alignment
						let i = activity.case_alignments.findIndex(o=>o.cfitem.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							activity.case_alignments.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.choose_activity_standards(activity, activity_index)

						} else {
							// Add the case_alignment, which will include the cfitem and the framework_identifier (this is the format that Sparkl wants)
							activity.case_alignments.push(aligned_item)
							// re-initialize the chooser, showing the framework for the item we added
							this.choose_activity_standards(activity, activity_index)

							// set cdata.case_framework_identifier and last_case_item_identifier
							this.cdata.case_framework_identifier = aligned_item.framework_identifier
							this.cdata.last_case_item_identifier = aligned_item.cfitem.identifier
						}
					})
				})
			})
		},

		copy_standards_from_previous_activity(activity, activity_index) {
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
			let module = this.cdata.modules[activity.module_index]
			activity.case_alignments = object_copy(module.activities[activity_index-1].case_alignments)
		},

		remove_activity_standard(activity, activity_index, case_alignment) {
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
			let index = activity.case_alignments.findIndex(o=>o.cfitem.identifier==case_alignment.cfitem.identifier)
			if (index > -1) {
				activity.case_alignments.splice(index, 1)
			}
		},

		/////////////////////////////////
		// save the unit/lp; debounce this so we don't save too frequently
		save_collection() {
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(function() {
					this.$store.dispatch('save_learning_progression', {no_loader:true, lp:this.collection}).then(()=>{
					})
				}, 2000)
			}
			// call the debounce fn
			this.fn_debounced()
		},

		/////////////////////////////////
		get_collection_url() {
			this.$prompt({
				text: `Enter the url for the Canvas “Modules” page for the course. Example:<br><br>${this.base_href}/courses/2697/modules/`,
				initialValue: this.base_href + '/courses/34717/modules/',
				disableForEmptyValue: true,
				acceptText: 'Process files…',
				acceptIcon: 'fas fa-circle-arrow-right',
				acceptIconAfter: 'fas fa-circle-arrow-right',
				dialogMaxWidth: 600,
			}).then(collection_url => {
				collection_url = $.trim(collection_url)
				if (empty(collection_url)) return
				this.collection_url = collection_url
				this.process_collection_source_index()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_collection_source_index() {
			// Scrape “Modules” page from Canvas course
			this.cdata = {}
			U.loading_start()
			U.ajax('scrape_page', {user_id: this.user_info.user_id, url: this.collection_url}, result=>{
				if (result.status != 'ok') {
					console.log('Error scraping modules_url')
					return
				}

				let modules = []
				let $modules_jq = $(result.html)
				console.log('success', $modules_jq)

				// PROCESS EACH MODULE MODULE -- each .context_module div is a module
				let n_activities = 0
				$modules_jq.find('.context_module').each((module_index, module_el)=>{
					if (this.params.modules && !this.params.modules.includes(module_index)) return

					modules[module_index] = $.extend(true, {}, this.default_module)

					// within the module, each context_module_item is a canvas item
					let $module = $(module_el)
					// console.log('processing module ' + module_index)

					// look for module title
					let mt = $module.find('h2').text()
					if (mt) modules[module_index].title = mt

					// PROCESS AN ACTIVITY IN A MODULE
					$module.find('.context_module_item').each((activity_index, activity_el)=>{
						if (this.params.lessons && !this.params.lessons.includes(activity_index)) return

						// note that we have to set everything we need for reactivity here...
						let activity = object_copy($.extend(true, {}, this.default_activity, {
							module_index: module_index,
							activity_index: activity_index,
						}))
						modules[module_index].activities[activity_index] = activity

						let $activity_jq = $(activity_el)
						// find title here; if string starts with non-word character(s) (e.g. a unicode), strip them
						let title = $.trim($activity_jq.find('span.title').text())
						title = title.replace(/^\W+/, '')
						activity.title = title
						
						// first a inside .module-item-title div is the link to the lesson
						// <a title="CE - Colonial Era Module Overview" class="ig-title title item_link" href="/courses/2706/modules/items/420367">CE - Colonial Era Module Overview</a>
						let activity_href = $activity_jq.find('a').attr('href')

						if (!activity_href) {
							activity.errors.push(sr('Couldn’t find href for external_url_link: $1', activity_index))
							return
						}

						// console.log(activity_href)

						// if activity_href is a full link (starting with https), it's an external document -- probably a .docx or .pdf
						if (activity_href.search(/^http/) > -1) {
							// in this case, we'll make an activity with this as the link
							console.log('external link: ' + activity_href)

							activity.link_url = activity_href

						} else {
							// else url should be a relative link to the lesson page; add to the activity
							activity.href = activity_href
							++n_activities
						}

					// FINISHED PROCESSING ACTIVITY
					})
				// FINISHED PROCESSING MODULE
				})
				U.loading_stop()

				if (modules.length == 0) {
					this.$alert('Couldn’t find any modules to process. Are you sure you entered the url correctly?')
					return
				}
				let o = {
					collection_url: this.collection_url,
					collection_title: $modules_jq.find('.mobile-header-title div').html(),
					n_activities: n_activities,
					modules: modules,
				}
				this.cdata = o
			});
		},

		/////////////////////////////////
		scrape_activity(activity) {
			// add the activity to the queue
			activity.processing = true
			this.scrape_activity_queue.push(activity)
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`

			// reset some things about the activity here, before we get started, so they don't show up in the display
			activity.errors = []
			activity.n_h5p_to_convert = 0
			activity.converted_h5p_n = 0
			activity.unconverted_h5p_n = 0
			activity.unconverted_h5p_audio_n = 0
			activity.h5ps = []
			activity.stars_available = 0
			activity.fatal_error = false
			
			activity.scraped = false
			activity.created = false

			// if the queue was empty, start it running; if the queue wasn't empty, this activity will be processed when the previous activity in the queue finishes
			if (this.scrape_activity_queue.length == 1) {
				this.scrape_queued_activities()
			}
		},

		scrape_module_activities(module) {
			// scrape any activities in the module that haven't already been scraped
			let scrape_all = false
			// or if we've already scraped or converted them all and the user clicked to re-convert, scrape them all
			if (this.module_scraped(module) || this.module_created(module)) scrape_all = true

			// skip the initial activity ("Module standards alignment") for each folder when settings say to do so
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1

			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (scrape_all || (!activity.scraped && !activity.created)) {
					this.scrape_activity(activity)
				}
			}
		},

		scrape_all_modules() {
			// skip the initial module if skip_first_module is 'on'
			let i = 0
			if (this.settings.skip_first_module == 'on') i = 1
			for (; i < this.cdata.modules.length; ++i) {
				this.scrape_module_activities(this.cdata.modules[i])
			}
		},

		scrape_queued_activities() {
			// console.log('this.get_and_process_activity: ' + this.scrape_activity_queue.length)
			let activity = this.scrape_activity_queue[0]

			let activity_href = this.base_href + activity.href

			// if we're re-scraping the last thing we tried (and we didn't get errors), skip the rescrape process
			let last_scrape = U.local_storage_get('bulk_import_last_scrape', {})
			if (last_scrape.activity_href == activity_href) {
				console.log('found last_scrape', last_scrape)
				activity.processing = false
				activity.html = last_scrape.html
				activity.num_blanks = this.calculate_num_blanks(activity, activity.html)
				activity.scraped = true
				this.create_sparkl_activity(activity)
				this.scrape_activity_queue.shift()
				if (this.scrape_activity_queue.length > 0) {
					setTimeout(()=>this.scrape_queued_activities(), 0)
				}
				return
			}

			// console.log('module link: ' + activity_href)
			U.ajax('scrape_page', {user_id: this.user_info.user_id, url: activity_href, use_headless_chrome: 'yes'}, result=>{
				// if we get to here we're done processing this activity (at least for now)...
				activity.processing = false

				if (result.status != 'ok') {	//  || U.random_int(10) > 2
					console.log('Error scraping module page', result)
					activity.errors.push('Error scraping module page')
					activity.fatal_error = true
				} else {
					let html = result.html
					// console.log('raw html', html)

					// find the .show-content.user_content div; if not found just look for .user_content
					let $content = $(html).find('.show-content.user_content')
					if ($content.length == 0) $content = $(html).find('.user_content')
					// console.log('got activity content', $content)

					if ($content.length == 0) {
						activity.errors.push(sr('Possible error: $content.length == 0'))
					}

					// extract the h1, which should be the same as the title we already have; if not found in $content look in h1
					let h1 = $content.find('h1').first().remove()
					if (h1.length == 0) h1 = $(html).find('h1').first()

					// if title starts with non-word character(s) (e.g. a unicode), strip them
					let title = $.trim(h1.text())
					title = title.replace(/^\W+/, '')

					if (empty(title)) {
						activity.errors.push('Possible error: No title found in html')
					} else if (title != activity.title) {
						activity.errors.push(sr('Possible error: titles mismatch: "$1" - "$2"', activity.title, h1.text()))
					}

					// replace mathjax spans with latex -- or with calls to the gavirtual mathjax image generator??
					$content.find('.math_equation_latex').replaceWith(function() {
						let latex = $(this).find('script').first().text()
						return '$' + latex + '$'
					})

					// try to get content html
					activity.html = $.trim($content.html())

					if (empty(activity.html)) {
						activity.errors.push('No html found for activity; you may just need to re-scrape')
					} else {
						// determine the total number of blanks to create for this part; if this is 0, there might be an error
						activity.num_blanks = this.calculate_num_blanks(activity, activity.html)

						// if we have html, we can convert if the user wants to try...
						activity.scraped = true
					}

					// if no errors, try to convert to sparkl now; else stop so that user sees error(s)
					if (activity.errors.length == 0) {
						this.create_sparkl_activity(activity)

						// save to local_storage to make debugging easier
						U.local_storage_set('bulk_import_last_scrape', {activity_href:activity_href, html: activity.html})
					}
				}

				// remove from the queue, then process the next activity if we have one
				this.scrape_activity_queue.shift()
				if (this.scrape_activity_queue.length > 0) {
					setTimeout(()=>this.scrape_queued_activities(), 0)
				}
			})
		},

		/////////////////////////////////
		// this fn actually creates the sparkl activity, on the sparkl server 
		create_sparkl_activity(activity) {
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
			activity.processing = true

			activity.n_h5p_to_convert = 0
			activity.converted_h5p_n = 0
			activity.unconverted_h5p_n = 0
			activity.unconverted_h5p_audio_n = 0
			activity.h5ps = []

			activity.activity_data = {
				title: activity.title,
				editors: `,${this.bulk_create_editor_email},`,
				exercises: [],
				// set completion_mode to off
				completion_mode: 'no',
				// set allow_retry to on
				allow_retry: 'yes',
				// set 'open-door' policy according to settings
			    student_access_policy: (this.settings.open_door == 'on') ? 'no_sign_in' : 'signed_in',
				// turn on/off game mode depending on settings
				default_activity_display_settings: {
					sound_effects: (this.settings.game_mode == 'on') ? 'on' : 'off',
					colors: (this.settings.game_mode == 'on') ? 'more' : 'less',
					sparkl_bot: (this.settings.game_mode == 'on') ? 'on' : 'off',
				},
				stars_available: 0,
				amd: {},
			}
			// add attribution if provided
			if (this.settings.attribution) activity.activity_data.amd.attribution = this.settings.attribution

			// align sparkl activity to activity.case_alignments (even if it's empty)
			activity.activity_data.case_alignments = object_copy(activity.case_alignments)

			// create/add exercises
			activity.exercises_data = []

			// single part
			if (this.settings.conversion_script == 'single_ir' || this.settings.conversion_script == 'single_bc') {
				this.create_single_exercise_activity(activity)
			
			// gavs_elementary...
			} else if (this.settings.conversion_script == 'gavs_elementary') {
				this.create_gavs_elementary_activity(activity)
			}

			this.save_sparkl_activity(activity)
		},

		save_sparkl_activity(activity) {
			// if activity is still pending, return; save_sparkl_activity should be called again when it's done
			if (activity.pending) {
				console.log('activity pending', object_copy(activity))
				return
			}

			// delete activity.animal_icons at this point; we don't need it anymore
			delete activity.animal_icons

			// set activity_data.exercises[] set exercise and activity_data stars_available values after we've finished creating the exercises
			activity.exercise_ids = []
			for (let exercise_data of activity.exercises_data) {
				activity.activity_data.exercises.push({exercise_id: exercise_data.exercise_id})

				// also save exercise_ids separately, so that we retain them across work sessions
				activity.exercise_ids.push(exercise_data.exercise_id)

				exercise_data.stars_available = 0
				for (let query_id in exercise_data.queries) {
					exercise_data.stars_available += exercise_data.queries[query_id].stars_available ?? 0
				}
				activity.activity_data.stars_available += exercise_data.stars_available
			}
			// also store stars_available directly in activity record, so we can show it in the user display here
			activity.stars_available = activity.activity_data.stars_available

			// if we already have an activity_id, send it through too, so we update the existing activity
			if (activity.activity_id != 0) activity.activity_data.activity_id = activity.activity_id

			let activities = [{activity_data: activity.activity_data, exercises_data: activity.exercises_data}]
			console.log(`saving activity “${activity.title}”`, activities)

			// stringify the activities, and put it through encode_blocked_keywords so firewalls won't reject it
			// note though, that we don't send the encode_blocked_keywords flag through to the *cureum* server; rather the cureum service sends the encode_blocked_keywords through to the *sparkl* server
			let activities_string = U.encode_blocked_keywords(JSON.stringify(activities))

			activity.processing = true
			U.ajax('bulk_create_sparkl_activity', {sparkl_origin_override: this.sparkl_origin, user_id: this.user_info.user_id, sparkl_activities: activities_string}, result=>{
				// console.log('bulk_create_sparkl_activity', result)
				activity.processing = false

				if ((result?.status ?? '') != 'ok') {
					activity.errors.push('Error in bulk_create_sparkl_activity')
				} else {
					// we should get the activity_id in the result as follows:
					activity.activity_id = result.sparkl_rv.activity_ids[0]

					// mark activity as created -- ready to import to Cureum
					activity.created = true

					// if the activity has h5ps or errors, show them? [used to do this, but stopped]
					// if (activity.h5ps.length > 0) activity.h5ps_showing = true
					// if (activity.errors.length > 0) activity.errors_showing = true
				}
			})
		},

		/////////////////////////////////
		create_exercise_record_data(params) {
			let exercise_record_data = {
				exercise_id: params.exercise_id ?? U.new_uuid(),
				exercise_title: params.title ?? '',
				exercise_type: params.exercise_type ?? 'freeform',
				body: params.body ?? '',
				parts: params.parts ?? [],
				queries: params.queries ?? {},
			}

			return exercise_record_data
		},

		create_basic_content_part(exercise_record_data, params) {
			// create the bc query, unless params says not to
			if (params.no_bc_query !== true) {
				// the new part_index will be the starting exercise_record_data.parts.length
				let bc_query = new Basic_Content_Query({
					part_index: exercise_record_data.parts.length,
					stars_available: params.freeform_stars ?? 1,
					permanent: params.freeform_stars ? true : false,
				})
				exercise_record_data.queries[bc_query.uuid] = bc_query
			}

			// add params.body to exercise body, with a <hr> if needed
			if (!empty(exercise_record_data.body)) exercise_record_data.body += '<hr>'
			exercise_record_data.body += params.body

			// add part
			exercise_record_data.parts.push({
				part_type: 'basic_content',
				interactive_reading: 'off',
			})
		},

		create_interactive_reading_part(exercise_record_data, params) {
			// the new part_index sill be the starting exercise_record_data.parts.length
			let part_index = exercise_record_data.parts.length

			// create the ir query
			let ir_query = new Interactive_Reading_Query({part_index: part_index,})
			exercise_record_data.queries[ir_query.uuid] = ir_query

			// create one query per auto-blank
			for (let i = 0; i < params.num_blanks; ++i) {
				let query = new window.Hangman_Query({
					// blank_letters is set in the part
					blank_letters: 1,
					stars_per_letter: 1,
					part_index: params.part_index ?? 0,
					correct_answer:'AUTO',
					auto_blank: true,
					stars_available: 1,
				})
				exercise_record_data.queries[query.uuid] = query
			}

			// add params.body to exercise body, with a <hr> if needed
			if (!empty(exercise_record_data.body)) exercise_record_data.body += '<hr>'
			exercise_record_data.body += params.body

			// add part
			exercise_record_data.parts.push({
				part_type: 'interactive_reading',
				interactive_reading: 'on',
				auto_blanks: 'on',
				ab_count: 'fewer',
				ab_letters: '1',
			})
		},

		create_single_exercise_activity(activity) {
			// remove <hr>'s, because they would break the body into different parts, which we don't want here
			activity.html = activity.html.replace(/<hr>/g, ' ')

			let o = {}
			if (activity.exercise_ids?.length > 0) o.exercise_id = activity.exercise_ids[0]	// re-use existing exercise_id if we have one
			let exercise_record_data = this.create_exercise_record_data(o)

			if (activity.link_url) {
				let body = `<p>${activity.title}: <a class="k-host-link-nobr" target="_blank" title="${activity.title}" href="${activity.link_url}"><i class="fas fa-file mr-2"></i>LINK</a></p>`
				this.create_basic_content_part(exercise_record_data, {body:body})

			} else {
				// create a freeform exercise if specified by settings...
				if (this.settings.conversion_script == 'single_bc') {
					this.create_basic_content_part(exercise_record_data, {body:activity.html})

				// or an ir exercise
				} else {
					this.create_interactive_reading_part(exercise_record_data, {body:activity.html, num_blanks:activity.num_blanks})
				}
			}			
			activity.exercises_data = [exercise_record_data]
		},

		standardized_gavs_elementary_header(key, text, icons) {
			// if the text starts with <h2, this is the header for the very first part; the <h2> should standalone, but we might have another header after that
			let h2 = ''
			if (text.search(/^(<h2[\s\S]+?<\/h2>)\s*(.*)/) == 0) {
				h2 = RegExp.$1
				text = $.trim(RegExp.$2)
				if (empty(text)) return h2
			}

			// let's just always use a random icon
			let icon = 'fas fa-' + icons[0]
			icons.shift()

			// let icon
			// if (key == 'watch') icon = 'fas fa-eye'
			// else if (key == 'assignment') icon = 'fas fa-file-circle-check'
			// else icon = 'fas fa-' + icons[U.random_int(this.animal_icons.length)]
			// else icon = 'fas fa-bolt'

			return `${h2}<div style="display:flex; align-items:center; margin-bottom:12px;"><div class="ml-2"><i class="${icon}" style="font-size:40px;color:#4527a0"> </i></div><div class="ml-1" style="flex:0 1 auto; font-size:20px; font-weight:bold; font-family:Roboto, sans-serif;">${text}</div></div>`

			// 'alicorn', 'ant', 'bat', 'bee', 'bird', 'cat', 'cow', 'crab', 'crow', 'deer', 'dinosaur', 'dog', 'dolphin', 'dove', 'dragon', 'duck', 'elephant', 'fish-fins', 'fish', 'frog', 'hippo', 'horse', 'hydra', 'kiwi-bird', 'lobster', 'locust', 'monkey', 'mosquito', 'mouse-field', 'narwhal', 'octopus', 'otter', 'pegasus', 'pig', 'rabbit', 'raccoon', 'ram', 'sheep', 'shrimp', 'snake', 'spider', 'squid', 'squirrel', 't-rex', 'turtle', 'unicorn', 'whale', 'worm'

			// if (key == 'watch') icon = 'icon_watch_this'
			// else if (key == 'interaction') icon = 'icon_pause'
			// else icon = 'icon_assignment'
			// return `<div style="display:flex; align-items:center; margin-bottom:12px;"><img src="https://velocity.gadoe.org/images/gavs/${icon}.png" style="height:48px!important"><div class="ml-3" style="flex:0 1 auto; font-size:20px; font-weight:bold; font-family:Roboto, sans-serif;">${text}</div></div>`
		},

		create_gavs_elementary_activity(activity) {
			// // start our exercise record
			// let exercise_record_data = this.create_exercise_record_data({})
			// we're going to use the activity itself as our exercise_record_data at the start; then we combine everything into a proper exercise structure in finish_create_gavs_elementary_activity
			activity.parts = []
			activity.queries = {}
			activity.body = ''	// this we won't use; we'll initially keep each part body in the activity.parts items

			// make a random array for icons; this ensures that every header for each activity will have a unique icon
			activity.animal_icons = this.animal_icons.concat([])
			U.shuffle_array(activity.animal_icons)

			let html = activity.html

			// remove <hr>'s from the original html
			// html = activity.html.replace(/<hr>/g, ' ')

			// the "normal" font size is 14px; anything with this style, take it out
			// html = html.replace(/style="font-size: 14pt;"/g, '')

			// actually let's just take out *all* font-sizes
			html = html.replace(/font-size:\s*\w+/g, '')

			// use sr-only instead of screenreader-only class
			html = html.replace(/\bscreenreader-only\b/g, 'sr-only')

			//////////////////////////////////
			// get and pre-process top-level elements of html
			let top_nodes = $(html)
			let tjq = []
			for (let i = 0; i < top_nodes.length; ++i) {
				let node = top_nodes[i]

				// skip hr's (we process them as a part break)
				if (node.tagName == 'HR') {
					if (this.settings.log=='on') console.log(`${i}: adding HR`)
					tjq.push($('<hr>'))
					continue
				}

				let jq = $(node)
				// pull out empty text nodes and blank lines from top_nodes
				if (node.nodeType == 3 && empty($.trim(jq.text()))) {
					if (this.settings.log=='on') console.log(`${i}: skipping empty text node`)
					continue
				}

				// we will assume that if the html doesn't include an iframe or img, and the text is empty, it is empty (this catches, e.g., if the div/p only includes a <br> element)
				let html = jq.html()
				let text = $.trim(jq.text())
				if (empty(html.replace(/(&nbsp;)|\s+/g, ''))
					|| (empty(text) && !html.includes('img') && !html.includes('iframe'))) {
					if (this.settings.log=='on') console.log(`${i}: skipping empty ${node.tagName} element`, jq.html())
					continue
				}

				// if this isn't a text node and it includes one or more br, separate everything after the br into a separate p tag
				// if ((node.tagName == 'H2' || node.tagName == 'H3') && html.includes('<br>')) {
				if (node.nodeType != 3 && !(node.tagName == 'H2' || node.tagName == 'H3') && html.includes('<br>')) {
					let arr = html.split('<br>')
					// add nodes for each item after br's
					for (let j = 1; j < arr.length; ++j) {
						jq = $(`<p>${arr[j]}</p>`)
						let html = jq.html()
						let text = jq.text()
						// again skip empties
						if (empty(html.replace(/(&nbsp;)|\s+/g, ''))
							|| (empty(text) && !html.includes('img') && !html.includes('iframe'))) {
							continue
						}
						// console.log(`${i}.${j}: adding extra p element for h2/h3`, jq.html())
						if (this.settings.log=='on') console.log(`${i}.${j}: adding extra p element for h2/h3`, jq.html())
						tjq.push(jq)
					}
					// reset jq to "original" node
					jq = $(`<${node.tagName}>${arr[0]}</${node.tagName}>`)
				}
				
				// console.log(`${i}: adding ${node.tagName} element`, jq.html())
				if (this.settings.log=='on') console.log(`${i}: adding ${node.tagName} element`, jq.html())
				tjq.push(jq)
			}
			if (this.settings.log=='on') console.log('top_nodes', tjq)

			//////////////////////////////////
			// extract banner, footer, and attribution, and do some other cleanup
			let banner = ''
			let footer = ''
			let attribution = ''
			for (let i = 0; i < tjq.length; ++i) {
				let jq = tjq[i]
				let node = jq[0]
				let html = jq.html()

				// just skip by hr's
				if (node.tagName == 'HR') {
					if (this.settings.log=='on') console.log(`${i}: skipping hr`)

				// look for the banner
				} else if (html.search(/(<img [^>]+\/Banners\/[^>]+>)/) > -1) {
					let img = RegExp.$1
					banner = `<div>${img}</div>`
					if (this.settings.log=='on') console.log(`${i}: Found banner`, banner)
					tjq.splice(i, 1); --i; continue;

				// look for the footer image line
				} else if (html.search(/(<img [^>]+\/Footers\/[^>]+>)/) > -1 || html.search(/(<img [^>]+\/5749438[^>]+>)/) > -1) {
					footer = RegExp.$1
					footer = `<div>${footer}</div>`
					if (this.settings.log=='on') console.log(`${i}: Found footer`, footer)
					tjq.splice(i, 1); --i; continue;
					
				// look the attribution line, by text; or assume anything after the footer is attribution
				} else if (html.includes('CC BY ') || !empty(footer)) {
					if (this.settings.log=='on') console.log(`${i}: Found attribution`, jq)
					if (!empty(attribution)) attribution += '<br>'
					attribution += html 
					// attribution = node.outerHTML.replace(/<p/, '<p style="line-height:12px;font-size:10px;text-align:center;clear:both"')
					tjq.splice(i, 1); --i; continue;

				// look for headers
				} else if (node.tagName == 'H2' || node.tagName == 'H3' || node.tagName == 'H4') {
					// skip 'Introduction' header
					if (jq.text().toLowerCase().includes('introduction')) {
						if (this.settings.log=='on') console.log(`${i}: skipping introduction header`)
						tjq.splice(i, 1); --i; continue;
					} else {
						if (this.settings.log=='on') console.log(`${i}: skipping header`, html)
					}

				} else {
					if (this.settings.log=='on') console.log(`${i}: skipping`, html)
				}
			}

			// finalize attribution html
			if (!empty(attribution)) {
				attribution = `<p style="line-height:12px;font-size:10px;text-align:center;clear:both">${attribution}</p>`
			}

			//////////////////////////////////
			// now create/look for parts
			let i = 0		// line number for console.log
			let part_body = ''
			let voki = ''
			let part_header = ''
			let last_header_tagName = ''
			while (tjq.length > 0) {
				let jq = tjq[0]
				let node = jq[0]
				let html = jq.html()
				let tagName = node.tagName

				// if the html includes an iframe, make tagName DIV -- sometimes iframes accidentally get put in header tags (e.g.)
				if (html.includes('<iframe')) tagName = 'DIV'

				// when we find a header...
				if (
						(html.search(/(<img [^>]+(Icon.Pause)%20and%20Think[^>]+>)/) > -1 && html.search(/<strong>Interactive/) > -1)
						|| html.search(/(<img [^>]+(Icon.Assignment).[^>]+>)/) > -1 
						|| tagName == 'H2' || tagName == 'H3' || tagName == 'H4'
						|| tagName == 'HR'
					) {
					if (this.settings.log=='on') console.log(`${i}: Found header`, node.outerHTML)

					// if part_body is empty (and this isn't an HR), this is the header for a new part (we must have just started processing, or it's a part for a video or h5p), so just save part_header and wait for an h5p, video, or another header
					if (empty(part_body) || (activity.parts.length == 0 && part_body.search(/^<h2/) == 0)) {	// if this is the first part and  part_body starts with <h2, it's the first part and we added a "top" header to the body
						// if this is an hr and part_body is empty, just skip it
						if (tagName == 'HR') {
							if (this.settings.log=='on') console.log(`${i}: FOUND HR, BUT part_body IS EMPTY`)
						} else {
							// for the very first header in the activity, use an h2 with a special class
							if (activity.parts.length == 0 && empty(part_header)) {
								part_header = `<h2 class="k-gavs-activity-title">${html}</h2>`

							} else {
								// separate multiple headers, except for the first part (where the first header will be an <h2>)
								if (!empty(part_header) && activity.parts.length > 0) {
									if (this.settings.log=='on') console.log(`${i}: MULTIPLE HEADERS FOR ONE PART`)
									part_header += ' — '
								}
								part_header += jq.text()
								if (empty(part_header)) part_header = 'Let’s Do It!'
							}
						}

					// else create a part with the part_body (or ir??)
					} else {
						let gea_type = 'text'
						let fn = 'create_basic_content_part'
						let o = {body:part_body, part_index: activity.parts.length}

						// if we found a voki, set gea_type to 'voki', and do interactive reading
						if (!empty(voki)) {
							gea_type = 'voki'
							fn = 'create_interactive_reading_part'
							o.num_blanks = this.calculate_num_blanks(activity, part_body)

						// 	// if we have a header, add it
						// 	if (part_header && part_header != '???') part_body = this.standardized_gavs_elementary_header('interaction', part_header, activity.animal_icons) + part_body
						// 	// if (part_header && part_header != '???') part_body = `<h3>${part_header}</h3>${part_body}`
						// 	part_header = ''
						// } else {
						// 	if (part_header) part_body = this.standardized_gavs_elementary_header('interaction', part_header, activity.animal_icons) + part_body
						}

						// add a header to part_body
						part_body = this.standardized_gavs_elementary_header('interaction', (part_header || (o.part_index == 0 ? 'Introduction' : 'Let’s Do It!')), activity.animal_icons) + part_body
						// if (part_header && part_header != '???') part_body = `<h3>${part_header}</h3>${part_body}`

						part_body = this.process_first_part_body(activity, banner, part_body, footer, attribution)

						if (this.settings.log=='on') console.log(`CREATING PART INDEX ${activity.parts.length} for previous content (${gea_type})`)

						this[fn](activity, o)
						activity.parts[activity.parts.length-1].gea_type = gea_type
						activity.parts[activity.parts.length-1].body = part_body
						activity.parts[activity.parts.length-1].part_header = part_header

						// reset part_body/voki; set part_header to this header's text (skipping, e.g., images)
						part_body = ''
						voki = ''
						part_header = jq.text()
						last_header_tagName = tagName
					}

				// look for voki
				} else if (html.search(/(<iframe [^>]+www.voki.com[^>]+>.*?<\/iframe>)/) > -1) {
					let iframe = RegExp.$1
					let h, w
					if (iframe.search(/height="(\d+)px"/)) h = RegExp.$1
					if (iframe.search(/width="(\d+)px"/)) w = RegExp.$1

					// reduce size of iframe to max 500px
					// this doesn't fit great in small screens, but it does work OK, and if we try to reduce smaller voki doesn't work well.
					if (h && w) {
						let f = (w < 500) ? 1 : 500 / w
						iframe = iframe.replace(/height="(\d+)px"/, `height="${h*f}px"`)
						iframe = iframe.replace(/width="(\d+)px"/, `width="${w*f}px"`)
						// iframe = iframe.replace(/style="/, 'style="max-width:200px;')	// this doesn't seem to do anything
					}
					// console.log(`h ${h} / w ${w}`, iframe)
					
					// if we already have voki, probably an error
					if (!empty(voki)) {
						if (this.settings.log=='on') console.log(`${i}: MULTIPLE VOKIS FOR ONE PART`)
					}
					// voki += `<div style="float:right; margin-left:16px">${iframe}</div>`
					voki += `<div style="text-align:center">${iframe}</div>`
					part_body += voki
					if (this.settings.log=='on') console.log(`${i}: Found voki`, voki)

				// look for the video iframe
				// <iframe id="kaltura_player_1720881744" title="embedded content" src="https://cdnapisec.kaltura.com/p/2294801/sp/229480100/embedIframeJs/uiconf_id/40827622/partner_id/2294801?iframeembed=true&amp;playerId=kaltura_player_1720881744&amp;entry_id=1_0hxk1cfm" width="700" height="525" allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen" allow="autoplay *; fullscreen *; encrypted-media *"></iframe>
				} else if (html.search(/(<iframe [^>]+gavirtual.instructuremedia.com[^>]+>.*?<\/iframe>)/) > -1
					|| html.search(/(<iframe [^>]+kaltura.com[^>]+>.*?<\/iframe>)/) > -1) {
					// <p><iframe src="https://gavirtual.instructuremedia.com/embed/c034fa02-d224-49f8-8c7e-1238aa5d5099" width="560px" height="320px" allowfullscreen="allowfullscreen" allow="autoplay *"></iframe></p>
					let iframe = RegExp.$1

					// TODO: if we haven't already saved an intro part and we have part_body, the author must have forgotten the header, so save the intro part now
					
					if (this.settings.log=='on') console.log(`${i}: Found video`, iframe)
					if (this.settings.log=='on') console.log(`CREATING PART INDEX ${activity.parts.length} for video`)

					// if part_body is empty, add standardized text
					if (empty(part_body)) part_body = `<p>Watch the video:</p>`
					part_body += `<div style="clear:both; text-align:center;">${iframe}</div>`
					
					part_body = this.standardized_gavs_elementary_header('watch', (part_header || 'Video'), activity.animal_icons) + part_body
					part_body = this.process_first_part_body(activity, banner, part_body, footer, attribution)

					// give the student 5 stars for watching the video
					this.create_basic_content_part(activity, {body:part_body, freeform_stars:5})
					activity.parts[activity.parts.length-1].gea_type = 'video'
					activity.parts[activity.parts.length-1].body = part_body
					activity.parts[activity.parts.length-1].part_header = ''

					// reset part_body/part_header
					part_body = ''
					part_header = ''

				// look for an h5p iframe
				} else if (html.search(/(<iframe [^>]+src="([^"]+h5pplayer.gadoe.org[^"]+course=(.*?))"[^>]+>.*?<\/iframe>)/) > -1
				        || html.search(/(<iframe [^>]+src="([^"]+moodle.gadoe.org\/gavs\/mod\/hvp\/embed.php\?(.*?))"[^>]+>.*?<\/iframe>)/) > -1) {
					// <iframe title="RevScienceM2L1" src="https://h5pplayer.gadoe.org/h5pplayer/test/embed.php?course=revsciencem2l1" width="700" height="400" allowfullscreen="allowfullscreen" allow="geolocation *; microphone *; camera *; midi *; encrypted-media *"></iframe>
					// <iframe title="embedded content" src="https://moodle.gadoe.org/gavs/mod/hvp/embed.php?id=12515" width="100%" height="326" allowfullscreen="allowfullscreen"></iframe>
					let iframe = RegExp.$1
					let h5p_url = RegExp.$2
					let h5p_id = RegExp.$3

					if (this.settings.log=='on') console.log(`${i}: Found h5p`, iframe)
					if (this.settings.log=='on') console.log(`CREATING PART INDEX ${activity.parts.length} for h5p`)

					part_body += `<div data-h5p_id="${h5p_id}" style="clear:both">${iframe}</div>`

					part_body = this.standardized_gavs_elementary_header('interaction', (part_header || 'Interactive'), activity.animal_icons) + part_body
					part_body = this.process_first_part_body(activity, banner, part_body, footer, attribution)

					this.create_basic_content_part(activity, {body:part_body, no_bc_query: true})	
					// note: convert_h5p_complete will add a bc_query if the h5p doesn't get converted
					activity.parts[activity.parts.length-1].gea_type = 'h5p'
					activity.parts[activity.parts.length-1].body = part_body
					activity.parts[activity.parts.length-1].part_header = part_header

					// try to convert to sparkl (the convert_h5p fn runs asynchronously, since it needs to retrieve json files)
					activity.n_h5p_to_convert += 1
					this.convert_h5p(activity, h5p_url, h5p_id)

					// reset part_body/part_header
					part_body = ''
					part_header = ''

				// else it's just content...
				} else {
					if (this.settings.log=='on') console.log(`${i}: Adding line to part`, node.outerHTML)
					part_body += node.outerHTML
				}

				++i
				tjq.shift()

				// go until there are no lines left
			}

			// if we have part content and/or a part_header at the end, create a final part with this content
			if (!empty(part_body) || !empty(part_header)) {
				if (this.settings.log=='on') console.log(`CREATING PART INDEX ${activity.parts.length} for final part`)

				if (part_header && part_header != '???') part_body = this.standardized_gavs_elementary_header('interaction', part_header, activity.animal_icons) + part_body
				part_body = this.process_first_part_body(activity, banner, part_body, footer, attribution)

				this.create_basic_content_part(activity, {body: part_body})	// , no_bc_query: true
				activity.parts[activity.parts.length-1].gea_type = 'text'
				activity.parts[activity.parts.length-1].part_header = part_header
				activity.parts[activity.parts.length-1].body = part_body
			}

			this.finish_create_gavs_elementary_activity(activity)
		},

		process_first_part_body(activity, banner, part_body, footer, attribution) {
			// for first part, add banner, footer, and attribution
			if (activity.parts.length == 0) {
				part_body = `${banner}${part_body}${footer}${attribution}`
			}
			return part_body
		},

		finish_create_gavs_elementary_activity(activity) {
			// if we're processing an h5p activity, return; convert_h5p will call finish_create_gavs_elementary_activity again when it's done
			if (activity.n_h5p_to_convert > 0) {
				// console.log('waiting for n_h5p_to_convert: ' + activity.n_h5p_to_convert)
				// set pending to true so that calls to save_sparkl_activity won't complete until we're done
				activity.pending = true
				return
			}

			// TODO: option whether or not to include the CR query; for now we will *NOT* do this
			if (false) {
				// create cr query
				let cr_query = new CR_Query({
					answer_type: 'flex',
					notes_mode: true,
					stars_available: 12,
				})
				activity.queries[cr_query.uuid] = cr_query

				// figure out where to put the cr
				let last_part = activity.parts[activity.parts.length-1]
				// if last part ended in a recorder h5p, we will have marked it as type cr, so that's where the cr goes
				if (last_part.gea_type == 'cr') {
				
				// else if last part was type text, we also insert the cr in the last part
				} else if (last_part.gea_type == 'text') {

				// else insert a new part for the cr
				} else {
					this.create_basic_content_part(activity, {body: '', no_bc_query: true})
					last_part = activity.parts[activity.parts.length-1]
					last_part.body = this.standardized_gavs_elementary_header('interaction', 'Activity', activity.animal_icons) + '<p>Make your activity submission below.</p>'
				}

				// add the cr_query to othe last part, and mark it as the cr part
				cr_query.part_index = activity.parts.length-1
				last_part.body += `<p>[[${cr_query.uuid}]]</p>`
				last_part.gea_type = 'cr'
			}

			// assemble exercise body
			let body = ''
			for (let i = 0; i < activity.parts.length; ++i) {
				let part = activity.parts[i]
				if (body != '') body += '<hr>'

				// // insert header if necessary
				// if (part.gea_type == 'video') {
				// 	// part.body = this.standardized_gavs_elementary_header('watch', (part.part_header || 'Video'), activity.animal_icons) + part.body
				// } else if (part.gea_type == 'h5p' || (part.gea_type == 'converted_h5p' && part.part_type == 'basic_content')) {
				// 	// part.body = this.standardized_gavs_elementary_header('interaction', (part.part_header || 'Interactive'), activity.animal_icons) + part.body
				// } else if (part.gea_type == 'cr') {
				// 	// part.body = this.standardized_gavs_elementary_header('assignment', 'Activity', activity.animal_icons) + part.body
				// // we used to assume google was slideshows, now let's just say "Interaction"
				// } else if (part.body.includes('docs.google.com')) {
				// 	// part.body = this.standardized_gavs_elementary_header('interaction', 'Interactive', activity.animal_icons) + part.body
				// } else if (i != 0 && part.part_header && part.part_header != '???') {
				// 	// part.body = this.standardized_gavs_elementary_header('interaction', part.part_header, activity.animal_icons) + part.body
				// }
				// if we converted an h5p to a scaffolded response part, no header

				body += part.body
			}

			// create the exercise_record_data, set pending to false, and save the activity
			activity.exercises_data = [this.create_exercise_record_data({body:body, parts: activity.parts, queries: activity.queries})]

			// if activity.pending was true set to true, set it to false and call save_sparkl_activity
			// (if we didn't need to wait for any h5ps, we would have gotten here right after create_gavs_elementary_activity ran, and the caller of create_gavs_elementary_activity will call save_sparkl_activity)
			if (activity.pending) {
				activity.pending = false
				this.save_sparkl_activity(activity)
			}
			// DONE!!
		},

		/////////////////////////////////
		async convert_h5p(activity, h5p_url, h5p_id) {
			// convert the h5p in the currently-final activity part
			let part_index = activity.parts.length-1

			// for this url signature, we can't currently convert
			// original h5p url: https://moodle.gadoe.org/gavs/mod/hvp/embed.php?id=12515
			if (h5p_url.includes('moodle.gadoe.org')) {
				console.warn('skipping moodle h5p')
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			}

			/*
				original h5p url: https://h5pplayer.gadoe.org/h5pplayer/test/embed.php?course=revsciencem2l1
						h5p.json: https://h5pplayer.gadoe.org/h5pplayer/test/full_workspace/Courses/revsciencem2l1/h5p.json
					content.json: https://h5pplayer.gadoe.org/h5pplayer/test/full_workspace/Courses/revsciencem2l1/content/content.json
			*/
			let h5p_json_url = `https://h5pplayer.gadoe.org/h5pplayer/test/full_workspace/Courses/${h5p_id}/h5p.json`
			let content_json_url = `https://h5pplayer.gadoe.org/h5pplayer/test/full_workspace/Courses/${h5p_id}/content/content.json`
			// let h5p_json_url = h5p_url.replace(/^(.*)\/embed\.php\?course=(.*)/, '$1/full_workspace/Courses/$2/h5p.json')
			// let content_json_url = h5p_url.replace(/^(.*)\/embed\.php\?course=(.*)/, '$1/full_workspace/Courses/$2/content/content.json')
			if (this.settings.log=='on') console.log('convert_h5p starting', h5p_json_url, content_json_url)
			
			let h5p_json, content_json
			await U.fetch_json(h5p_json_url).then(json=>h5p_json=json).catch(error=>{
				console.log('error retrieving h5p_json: ' + error)
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			})
			if (empty(h5p_json)) {
				console.log('h5p_json empty')
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			}
			// get the json file
			await U.fetch_json(content_json_url).then(json=>content_json=json).catch(error=>{
				console.log('error retrieving h5p_json: ' + error)
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			})
			if (empty(content_json)) {
				console.log('content_json empty')
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			}
			// got the h5p_json and content_json

			// converter fns should add queries to activity exercise part and return html
			let converted_html

			// look at "mainLibrary" field for convertable h5p types
			let h5p_type = h5p_json.mainLibrary

			if (h5p_type == 'H5P.Blanks') converted_html = this.convert_h5p_blanks(activity, part_index, content_json)
			else if (h5p_type == 'H5P.DragText') converted_html = this.convert_h5p_drag_text(activity, part_index, content_json)
			else if (h5p_type == 'H5P.MultiChoice') converted_html = this.convert_h5p_multichoice(activity, part_index, content_json)
			else {
				// not a type we currently know what to do with; we'll keep it as the h5p iframe
				console.log(`h5p type ${h5p_type} not convertible`, content_json, h5p_json)
				return this.convert_h5p_complete(activity, part_index, '', h5p_type, h5p_id)
			}

			if (empty(converted_html)) {
				console.log(`error converting h5p type ${h5p_type}`, content_json, h5p_json)
			}
			this.convert_h5p_complete(activity, part_index, converted_html, h5p_type, h5p_id)
		},

		convert_h5p_complete(activity, part_index, converted_html, h5p_type, h5p_id) {
			let part = activity.parts[part_index]
			if (!empty(converted_html)) {
				// replace the h5p iframe with converted_html, and ignore the part_header
				// console.log('before', body)
				let re = new RegExp(`(<div data-h5p_id="${h5p_id}"[^>]*?>)(.*?)(</div>)`)
				part.body = part.body.replace(re, '$1' + converted_html + '$3')
				// console.log('after', .body)

				// mark the part as type 'converted_h5p'
				part.gea_type = 'converted_h5p'

				activity.converted_h5p_n += 1
				activity.h5ps.push(h5p_type + ': CONVERTED')
				
			} else {
				// for the H5P.AudioRecorder type...
				if (h5p_type == 'H5P.AudioRecorder') {
					// remove the h5p iframe altogether
					let re = new RegExp(`(<div data-h5p_id="${h5p_id}"[^>]*?>)(.*?)(</div>)`)
					part.body = part.body.replace(re, '')

					// mark the part as type 'cr', and add the cr query
					part.gea_type = 'cr'
					let cr_query = new CR_Query({
						part_index: part_index,
						// answer_type: 'flex',
						answer_type: 'audio',
						min_audio_len: 1,
						notes_mode: true,
						stars_available: 12,
					})
					activity.queries[cr_query.uuid] = cr_query
					part.body += `<p>[[${cr_query.uuid}]]</p>`

					// count H5P.AudioRecorders separately, since we're replacing those with Sparkl CRs
					activity.unconverted_h5p_audio_n += 1
					activity.h5ps.push(h5p_type + ': NOT CONVERTED (replaced with CR)')

				} else {
					// else leave the h5p iframe alone, and leave gea_type as 'h5p'
					activity.unconverted_h5p_n += 1
					activity.h5ps.push(h5p_type + ': NOT CONVERTED')

					// in this case we need to the part to have a bc_query
					let bc_query = new Basic_Content_Query({
						part_index: part_index,
						stars_available: 1,
						permanent: false,
					})
					activity.queries[bc_query.uuid] = bc_query
				}
			}

			// reduce n_h5p_to_convert, then call finish_create_gavs_elementary_activity, which will actually save if it's down to 0
			activity.n_h5p_to_convert -= 1
			this.finish_create_gavs_elementary_activity(activity)
		},

		// convert H5P.MultiChoice
		convert_h5p_multichoice(activity, part_index, content_json) {
			let part = activity.parts[part_index]
			// 'question' field is the prompt; convert it (as well as choices) from divs/ps to breaks
			let prompt = U.blocks_to_breaks(content_json.question)

			// choices and possible feedback are in 'answers'
			let choices = []
			let has_feedback = false
			let feedback = []
			let correct_choice_index = []
			for (let i = 0; i < content_json.answers.length; ++i) {
				// { "correct": false,
				//   "tipsAndFeedback": { "tip": "", "chosenFeedback": "", "notChosenFeedback": "" },
				//   "text": "<div>water</div>\n" }
				let a = content_json.answers[i]

				// TODO: convert choice from divs/ps to breaks
				
				choices.push(U.blocks_to_breaks(a.text))
				if (empty(a.tipsAndFeedback?.chosenFeedback)) {
					feedback.push('')
				} else {
					feedback.push(U.blocks_to_breaks(a.tipsAndFeedback.chosenFeedback))
					has_feedback = true
				}

				// it looks like the h5p.MultiChoice type allows for multiple correct answers...
				if (a.correct) correct_choice_index.push(i)
			}
			if (!has_feedback) feedback = null
			
			if (correct_choice_index.length > 1) {
				// TODO: deal with this... for now reject
				console.log('H5P.MultiChoice has multiple correct answers')
				return ''
			}

			// create an interactive MC
			let query = new MC_Query({
				part_index: part_index,
				mode: 'standalone',
				prompt: prompt,
				choices: choices,
				correct_choice_index: correct_choice_index[0],
				feedback: feedback,
				not_sure_option: true,
				show_model: true,
				shuffle_choices: false,
			})
			activity.queries[query.uuid] = query
			if (this.settings.log=='on') console.log('MC query', query)

			// set part to type 'mc'
			part.part_type = 'mc'

			// replace the whole h5p with the query placeholder
			return `[[${query.uuid}]]`
		},

		// convert H5P.XXX
		convert_h5p_XXX(activity, part_index, content_json) {
			let part = activity.parts[part_index]
			// 'text' field is the prompt; insert class to make it look like a prompt in Sparkl
			let html = ''
			html += content_json.text.replace(/<(p|div)\b/, '<$1 class="k-exercise__question-prompt"')

			if (this.settings.log=='on') console.log('H5P.MultiChoice html', html)

			return html
		},

		// convert H5P.Blanks to fill-in blanks
		convert_h5p_blanks(activity, part_index, content_json) {
			let part = activity.parts[part_index]
			let html = ''

			// 'text' field is the prompt
			let prompt = U.blocks_to_breaks(content_json.text)

			// 'questions' field has the questions...
			// "<p>&nbsp;</p>\n\n<p dir=\"ltr\" role=\"presentation\">Winter is one of the 4 *seasons*.</p>\n\n<p dir=\"ltr\" role=\"presentation\">In Winter people need a heavy *coat* to stay warm outside.</p>\n\n<p dir=\"ltr\" role=\"presentation\">Animals have *fur*&nbsp;to keep them warm.</p>\n\n<p dir=\"ltr\" role=\"presentation\">In Winter the Arctic Fox will turn *white*.&nbsp;</p>\n"
			let nodes = $(`<div>${content_json.questions}</div>`).children()
			for (let i = 0; i < nodes.length; ++i) {
				let node = nodes[i]
				let jq = $(node)

				// pull out empty text nodes and blank lines 
				if (node.nodeType == 3 && empty($.trim(jq.text()))) {
					if (this.settings.log=='on') console.log(`h5p ${i}: skipping empty text node`)
					continue
				}
				if (empty(jq.html().replace(/(&nbsp;)|\s+/g, ''))) {
					if (this.settings.log=='on') console.log(`h5p ${i}: skipping empty ${node.tagName} element`, jq.html())
					continue
				}

				if (this.settings.log=='on') console.log(`h5p ${i}: adding line`, node.outerHTML)
				html += node.outerHTML
			}

			// blanks seem to just be surrounded by *'s
			html = html.replace(/(\*(.*?)\*)/g, ($0, $1, word) => {
				// create a hangman query for the word
				let query = new window.Hangman_Query({
					part_index: part_index,
					correct_answer: word,
				})
				activity.queries[query.uuid] = query

				if (this.settings.log=='on') console.log('adding hangman query', query)

				// replace with the query placeholder
				return `[[${query.uuid}]]`
			})

			// set part_type and part_prompt of the part
			part.part_type = 'scaffolded_response'
			part.part_prompt = prompt
			part.sr_dnd = 'off'	// drag-n-drop not allowed for this type

			if (this.settings.log=='on') console.log('H5P.Blanks: prompt, html', prompt, html)

			return html
		},

		// convert H5P.DragText to scaffolded response with drag-and-drop required
		convert_h5p_drag_text(activity, part_index, content_json) {
			let part = activity.parts[part_index]
			let html = ''

			// 'taskDescription' field is the prompt
			let prompt = U.blocks_to_breaks(content_json.taskDescription)

			// 'textField' field has the questions...
			// note that here, unlike in H5P.Blanks, we seem to not have html, or ag least not have <p>/<div> tags, so we'll insert them
			// "Magnets are made of rock and *metal*.\nObjects made with *iron* are attracted to magnets.\nThe force of attraction is caused by *magnetism*.\nOur planet, *Earth*, is a giant magnet.\nMost magnets are *man* made.\n*Trains* can move on magnets which make them float along the track."
			let lines = content_json.textField.split('\n')
			for (let line of lines) html += `<p>${line}</p>`

			// blanks seem to just be surrounded by *'s
			html = html.replace(/(\*(.*?)\*)/g, ($0, $1, word) => {
				// create a hangman query for the word
				let query = new window.Hangman_Query({
					part_index: part_index,
					correct_answer: word,
				})
				activity.queries[query.uuid] = query

				if (this.settings.log=='on') console.log('adding hangman query', query)

				// replace with the query placeholder
				return `[[${query.uuid}]]`
			})

			// set part_type and part_prompt of the part
			part.part_type = 'scaffolded_response'
			part.part_prompt = prompt
			part.sr_dnd = 'on'	// drag-n-drop required for this type

			if (this.settings.log=='on') console.log('H5P.DragText: prompt, html', prompt, html)

			return html
		},

		/////////////////////////////////
		export_activity_links() {
			// skip the initial module
			// TODO: allow operator to specify modules to be skipped
			let text = ''
			let i = 1
			for (; i < this.cdata.modules.length; ++i) {
				let module = this.cdata.modules[i]
				text += `${module.title}\n`

				let j = 0
				if (this.settings.skip_first_activity_of_module == 'on') j = 1
				for (; j < module.activities.length; ++j) {
					let activity = module.activities[j]
					text += `${this.sparkl_origin}/${activity.activity_id}\t${activity.title}\n`
				}
				text += '\n'
			}

			let dialog_text = `<textarea style="border:1px solid #999; padding:8px; border-radius:10px; font-family:monospace; font-size:10px; line-height:12px; width:100%; height:500px">${text}</textarea>`

			this.$confirm({
				dialogMaxWidth:800, 
				text:dialog_text, 
				title:'Export Activity Links',
				acceptText: 'Done',
				cancelText: 'Copy to Clipboard',
			}).then(y => {
				
			}).catch(n=>{
				U.copy_to_clipboard(text)
			}).finally(f=>{})
		},

		all_modules_hp5_info() {
			let text = ''
			let o = {}
			// skip the initial module
			let i = 1
			for (; i < this.cdata.modules.length; ++i) {
				let module = this.cdata.modules[i]
				text += `${module.title}\n`

				let j = 0
				if (this.settings.skip_first_activity_of_module == 'on') j = 1
				for (; j < module.activities.length; ++j) {
					let activity = module.activities[j]
					text += `${i}.${j}: `
					for (let k = 0; k < activity.h5ps.length; ++k) {
						let h5p = activity.h5ps[k]
						if (k > 0) k += ', '
						text += h5p

						if (empty(o[h5p])) o[h5p] = 0
						o[h5p] += 1
					}
					text += ` [${activity.title}]\n`
				}
				text += '\n'
			}

			text = '\n' + text
			for (let h5p in o) {
				text = `${h5p}: ${o[h5p]}\n${text}`
			}

			let dialog_text = `<textarea style="border:1px solid #999; padding:8px; border-radius:10px; font-family:monospace; font-size:10px; line-height:12px; width:100%; height:500px">${text}</textarea>`

			this.$confirm({
				dialogMaxWidth:800, 
				text:dialog_text, 
				title:'Activity H5P Summary',
				acceptText: 'Done',
				cancelText: 'Copy to Clipboard',
			}).then(y => {
				
			}).catch(n=>{
				U.copy_to_clipboard(text)
			}).finally(f=>{})
		},

		fetch_all_modules_contents() {
			let activity_ids = []
			// skip the initial module
			let i = 1
			for (; i < this.cdata.modules.length; ++i) {
				let module = this.cdata.modules[i]

				let j = 0
				if (this.settings.skip_first_activity_of_module == 'on') j = 1
				for (; j < module.activities.length; ++j) {
					let activity = module.activities[j]
					if (activity && activity.activity_id) {
						activity_ids.push(activity.activity_id)
					}
				}
			}

			U.ajax('get_sparkl_activities_exercise_list', {user_id: this.user_info.user_id, sparkl_activities: JSON.stringify(activity_ids)}, result=>{

				if ((result?.status ?? '') != 'ok') {
					this.$alert('Error in get_sparkl_activities_exercise_list')
				} else {
					// we should receive activity_and_exercise_data in result; see below for how to parse it
					this.fetch_all_modules_contents_finish(result.activity_and_exercise_data.data)
				}
			})
		},

		fetch_all_modules_contents_finish(activity_and_exercise_data) {
			let text = ''
			// skip the initial module
			let i = 1
			for (; i < this.cdata.modules.length; ++i) {
				let module = this.cdata.modules[i]
				text += `${module.title}\n`

				let j = 0
				if (this.settings.skip_first_activity_of_module == 'on') j = 1
				for (; j < module.activities.length; ++j) {
					let activity = module.activities[j]
					if (!activity || !activity.activity_id) continue

					// get returned activity_and_exercise_data if possible, and create body
					let body = ''
					let o = activity_and_exercise_data[activity.activity_id]
					if (empty(o)) body = '???'
					else {
						activity.retrieved_activity_and_exercise_data = o
						for (let exercise of o.exercises) {
							body += `=== Exercise ${exercise.exercise_id}\n`
							body += exercise.body.replace(/<hr>/g, '\n\n----- <hr> -----\n')
						}
					}
					body = `${i}.${j}: ${activity.activity_id} [${activity.title}]\n\n${body}\n\n`

					// store retrieved_body in activity
					activity.retrieved_body = $.trim(body)

					text += '=======================================================\n'
					text += body
				}
				text += '\n\n'
			}

			let dialog_text = `<textarea style="border:1px solid #999; padding:8px; border-radius:10px; font-family:monospace; font-size:10px; line-height:12px; width:100%; height:500px">${text}</textarea>`

			this.$confirm({
				dialogMaxWidth:800, 
				text:dialog_text, 
				title:'Fetch Activity Contents',
				acceptText: 'Done',
				cancelText: 'Copy to Clipboard',
			}).then(y => {
				
			}).catch(n=>{
				U.copy_to_clipboard(text)
			}).finally(f=>{})
		},

		register_pasted_activity_text() {
			this.pasted_activity_text = $('#activity_contents_to_push').val()
		},

		push_activity_contents() {
			let dialog_text = `<textarea id="activity_contents_to_push" style="border:1px solid #999; padding:8px; border-radius:10px; font-family:monospace; font-size:10px; line-height:12px; width:100%; height:500px" onkeyup="vapp.bulk_activity_import_component.register_pasted_activity_text()"></textarea>`

			this.$confirm({
				dialogMaxWidth:800, 
				text:dialog_text, 
				title:'Push Activity Contents',
				acceptText: 'Push It!',
			}).then(y => {
				// this.pasted_activity_text should have been registered.
				let activity_data = []
				// split into activities
				let pasted_activities = this.pasted_activity_text.split(/=========+.*\n/)
				for (let pa of pasted_activities) {
					if (empty(pa)) continue

					// pa should start with the activity info
					let mi, ai, sparkl_activity_id
					if (pa.search(/^(\d+)\.(\d+):\s+(\d+)/) > -1) {
						mi = RegExp.$1
						ai = RegExp.$2
						sparkl_activity_id = RegExp.$3
					} else {
						this.$alert('couldn’t get activity_info', pa)
						return
					}

					let activity = this.cdata.modules[mi].activities[ai]
					if (!activity) {
						this.$alert(`couldn’t get activity ${mi}.${ai}`)
						return
					}
					if (!activity.retrieved_body) {
						this.$alert(`no retrieved_body for activity ${mi}.${ai}`)
						return
					}

					// if body isn't changed from original, skip
					if ($.trim(pa) == activity.retrieved_body) {
						console.log(`${mi}.${ai}: NO CHANGES`)
						continue
					}

					// save updated activity
					let o = {
						activity_data: object_copy(activity.retrieved_activity_and_exercise_data.activity_data), 
						exercises_data: object_copy(activity.retrieved_activity_and_exercise_data.exercises),
					}
					delete o.activity_data.created_at
					delete o.activity_data.updated_at
					let exercise_text = pa.split(/\n=== Exercise /)
					// exercise_text[0] will be the activity data line that we parsed above; skip it
					for (let i = 1; i < exercise_text.length; ++i) {
						let et = exercise_text[i].replace(/^(\S+)\n/, '')
						let exercise_id = RegExp.$1

						let exercise_body = et.replace(/\n\n----- <hr> -----\n/g, '<hr>')
						let ei = o.exercises_data.findIndex(x=>x.exercise_id == exercise_id)
						if (ei == -1) {
							this.$alert(`${mi}.${ai}: invalid exercise_id ${exercise_id}`)
							return
						}

						// make sure we have the same number of parts as the original
						let part_count = exercise_body.split('<hr>').length
						if (part_count != o.exercises_data[ei].parts.length) {
							this.$alert(`${mi}.${ai}: part counts don’t match: was ${o.exercises_data[ei].parts.length} / now ${part_count}`)
							return
						}
						o.exercises_data[ei].body = exercise_body
						delete o.exercises_data[ei].created_at
						delete o.exercises_data[ei].updated_at
					}

					// push activity data to be sent to server
					activity_data.push(o)
					console.log(`${mi}.${ai}: UPDATING!!`)
				}

				if (activity_data.length == 0) {
					this.$alert('No activities have changed.')
				} else {
					this.$confirm({
						text: `This will update ${activity_data.length} activities. Proceed?`,
						acceptText: 'Proceed',
						focusBtn: true,		// focus on the accept btn when dialog is rendered
					}).then(y => {
						this.push_activity_contents_finish(activity_data)
					}).catch(n=>{console.log(n)}).finally(f=>{})
				}

			}).catch(n=>{
				console.log(n)
			}).finally(f=>{})
		},

		push_activity_contents_finish(activity_data) {
			U.loading_start()
			U.ajax('bulk_create_sparkl_activity', {sparkl_origin_override: this.sparkl_origin, user_id: this.user_info.user_id, sparkl_activities: JSON.stringify(activity_data)}, result=>{
				U.loading_stop()
				if ((result?.status ?? '') != 'ok') {
					activity.errors.push('Error in bulk_create_sparkl_activity')
				} else {
					// we should get the activity_ids in the result as follows:
					console.log('updated', result.sparkl_rv.activity_ids)
				}
			})
		},

		add_all_modules_to_unit() {
			// skip the initial module
			// TODO: allow operator to specify modules to be skipped
			let i = 1
			for (; i < this.cdata.modules.length; ++i) {
				this.add_module_to_unit(this.cdata.modules[i])
			}
		},

		add_module_to_unit(module) {
			// skip the initial activity ("Module standards alignment") for each folder when settings say to do so
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1

			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (activity.created && activity.activity_id != 0 && !activity.added_to_cureum) {
					this.add_to_unit(activity)
				}
			}
		},

		// PW: new 11/20/2024
		create_module_bank(module, module_number) {
			U.loading_start()
			// skip the initial activity ("Module standards alignment") for each folder when settings say to do so
			let i = 0
			if (this.settings.skip_first_activity_of_module == 'on') i = 1

			// get the exercise_ids for each created activity (currently we always have one exercise per activity)...
			let exercises = []
			let exercises_data = []
			let all_case_alignments = []
			for (; i < module.activities.length; ++i) {
				let activity = module.activities[i]
				if (activity.created && activity.activity_id != 0) {
					// if we don't have exercise_ids recorded (we didn't stop permanently recording them until 11/2024), get them here
					if (activity.exercise_ids?.length == 0) {
						activity.exercise_ids = []
						U.ajax('get_activity_exercise_list', {user_id: this.user_info.user_id, tool_activity_id: activity.activity_id}, result=>{
							U.loading_stop()
							if (result.status != 'ok') {
								this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
							}

							for (let e of result.activity_data.exercises) {
								activity.exercise_ids.push(e.exercise_id)
							}

							// re-call the fn after retreiving this activity's exercise (we might have to do this multiple times for each activity in the module; that's OK)
							this.create_module_bank(module, module_number)
						})
						return
					}
					for (let eid of activity.exercise_ids) {
						// this list gets pushed to activity_data
						exercises.push({exercise_id: eid})

						// record bank-specific data for exercises
						let exercise_data = {
							exercise_title: activity.title,
							exercise_id: eid,
							md: {
								attribution: this.settings.attribution,
								imported_ex_edited: 'no'
							},
						}
						// add CASE alignments
						if (activity.case_alignments?.length > 0) {
							exercise_data.case_alignments = []
							for (let ca of activity.case_alignments) {
								let a = {
									cfitem: {
										identifier: ca.cfitem.identifier,
										humanCodingScheme: ca.cfitem.humanCodingScheme,
										fullStatement: ca.cfitem.fullStatement,
										educationLevel: ca.cfitem.educationLevel,
									},
									framework_identifier: ca.framework_identifier
								}
								exercise_data.case_alignments.push(a)

								// add to all_case_alignments if not already there
								if (!all_case_alignments.find(x=>x.cfitem.identifier == a.cfitem.identifier)) {
									all_case_alignments.push(a)
								}
							}
						}
						exercises_data.push(exercise_data)
					}
				}
			}

			// create a bank activity with these exercise_ids, named after the module, with amd set to show it as a bank resource
			// hackish way to strip funky first character from module title
			let module_title = module.title
			if (module_title.charCodeAt(0) > 10000) {
				// the funky character takes up two chars of the string...
				module_title = $.trim(module_title.substr(2, 10000))
			}
			// if the module title doesn't already start with "Module", insert it
			if (module_title.search(/Module/) !== 0) module_title = `Module ${module_number}: ${module_title}`

			let activity_data = {
				title: module_title,
				editors: `,${this.bulk_create_editor_email},`,
				exercises: exercises,
				// set completion_mode to off
				completion_mode: 'no',
				// set allow_retry to on
				allow_retry: 'yes',
				// set 'open-door' policy according to settings
			    student_access_policy: (this.settings.open_door == 'on') ? 'no_sign_in' : 'signed_in',
				// turn on/off game mode depending on settings
				default_activity_display_settings: {
					sound_effects: (this.settings.game_mode == 'on') ? 'on' : 'off',
					colors: (this.settings.game_mode == 'on') ? 'more' : 'less',
					sparkl_bot: (this.settings.game_mode == 'on') ? 'on' : 'off',
				},
				// stars_available are not relevant for a bank
				stars_available: 0,
				amd: {
					// these amd values configure the activity as a bank
					flavor: 'velocity',
					open_to_exercise_bank: 'bravo',
					attribution: 'gavs',
					// TODO: move below to attribution data in config?
					import_list_exercise_header_text: 'Lesson',
					show_type_in_import_list: 'no',
					bravo_instructions: 'Preview Georgia Virtual Learning module lessons by clicking the <i style="color:#000;margin:-3px 4px 0 4px; font-size:18px;" class="fas fa-glasses"></i> icons below.<br>Select lessons and click the “IMPORT” button above to create activities for your students.'
				},
				case_alignments: all_case_alignments,
			}

			// if we already have an activity_id, send it through too, so we update the existing activity
			if (module.bank_activity_id != 0) activity_data.activity_id = module.bank_activity_id

			let activities = [{activity_data: activity_data, exercises_data: exercises_data}]
			console.log(`saving bank activity “${activity_data.title}”`, activities)

			// stringify the activities, and put it through encode_blocked_keywords so firewalls won't reject it
			// note though, that we don't send the encode_blocked_keywords flag through to the *cureum* server; rather the cureum service sends the encode_blocked_keywords through to the *sparkl* server
			let activities_string = U.encode_blocked_keywords(JSON.stringify(activities))

			U.ajax('bulk_create_sparkl_activity', {sparkl_origin_override: this.sparkl_origin, user_id: this.user_info.user_id, sparkl_activities: activities_string}, result=>{
				if ((result?.status ?? '') != 'ok') {
					this.$alert('Error in bulk_create_sparkl_activity')
				} else {
					// we should get the activity_id in the result as follows:
					module.bank_activity_id = result.sparkl_rv.activity_ids[0]
				}
				U.loading_stop()
			})
		},

		add_module_bank_to_unit(module, module_index) {
		},

		add_to_unit(activity) {
			this.last_clicked_activity_index = `${activity.module_index}_${activity.activity_index}`
			if (activity.activity_id == 0) {
				this.$alert('Activity not addable, because we don’t have an activity_id')
				return
			}

			// add the activity to the queue
			activity.processing = true
			this.add_to_unit_queue.push(activity)

			// if the queue was empty, start it running; if the queue wasn't empty, this activity will be processed when the previous activity in the queue finishes
			if (this.add_to_unit_queue.length == 1) {
				this.add_queued_activities_to_unit()
			}
		},

		add_queued_activities_to_unit() {
			console.log('this.add_queued_activities_to_unit: ' + this.add_to_unit_queue.length)
			let activity = this.add_to_unit_queue[0]

			// create new resource for the activity
			let r = new Resource({
				resource_id: 'new',	// triggers a new guid
				agency_sanctioned: this.collection.agency_sanctioned,
				type: 'sparkl',
				url: activity.activity_id,
				description: activity.title,
				creator: 0,
				// creator: this.bulk_create_editor_email,
			})

			// if activity has case_alignments, align cureum resource to standards
			if (activity.case_alignments.length > 0) {
				for (let case_alignment of activity.case_alignments) {
					let o = new CASE_Item(case_alignment.cfitem)
					o.framework_identifier = case_alignment.framework_identifier
					r.standards.push(o)
				}
			}

			console.log('new resource:', r)

			this.$store.dispatch('save_resource', { resource: r, no_loader: true }).then(saved_resource_data => {
				console.log('saved_resource_data', saved_resource_data)
				let r = new Resource(saved_resource_data)

				// save resource_id in activity
				activity.resource_id = r.resource_id

				// add the resource to the unit
				this.unit.resources.push(r)

				// if module_folders is on, create/add to existing module folder
				if (this.settings.module_folders == 'on') {
					let module = this.cdata.modules.find(x=>x.activities.includes(activity))
					if (!empty(module.module_folder_id)) {
						// if we already have a module_folder_id, make sure it still exists
						if (!this.unit.resource_tree.folders.find(x=>x.folder_id == module.module_folder_id)) {
							// if not clear module_folder_id so we create a new folder
							module.module_folder_id == ''
						}
					}

					// if we still don't have a folder for the module, create one
					if (empty(module.module_folder_id)) {
						let f = this.unit.create_resource_folder({title:module.title, parent_folder_id:'top'})

						// store id in module.module_folder_id
						module.module_folder_id = f.folder_id
					}
					console.log('module: ', module)

					// now add the folder assignment
					this.unit.add_item_to_folder({
						type: 'resource',
						resource_id: activity.resource_id,
						parent_folder_id: module.module_folder_id,
					})
				}

				// mark the activity as not processing and remove from the queue
				activity.processing = false
				activity.added_to_cureum = true
				this.add_to_unit_queue.shift()

				// process the next activity in the queue if we have one...
				if (this.add_to_unit_queue.length > 0) {
					setTimeout(()=>this.add_queued_activities_to_unit(), 0)

				// otherwise we're done with the queue, so save the collection
				} else {
					this.save_collection()
				}
			})
		},
	}
}
/*
Algebra: 2697
US History: 2706
Psychology: 3859

this.start()

this.start({
	course_id: 2706,
	no_save: true,
	modules: [0],
	lessons: [2,3],
})

this.start({
	course_id: 2694,
	modules: [0,1,2],
	lessons: [0,1,2],
})

this.start({
	course_id: 34717,
	modules: [2],
	lessons: [1],
	freeform: true,
	freeform_stars: 4,
})
this.start({
	course_id: 34730,
	modules: [2],
	lessons: [1],
	freeform: true,
	freeform_stars: 4,
})

this.start({
	course_id: 3859,
})
*/
</script>

<style lang="scss">
.k-bulk-activity-setting {
	display:flex;
	align-items:center;
	border:1px solid #999;
	border-radius:5px;
	padding-left:4px;
	margin-bottom:8px;

	.theme--light.v-text-field > .v-input__control > .v-input__slot:before { border:0!important }
}
</style>

