<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-lesson-edit">

	<!-- note magic constant for max-height, so that editor scrolls properly -->
	<div style="font-size:16px; padding-bottom:56px; overflow:auto; max-height:calc(100vh - 92px);">
		<div v-if="show_master_select" class="mt-1 mb-4">
			<v-select v-model="selected_master_id" :items="master_select_items" label="Master Lesson Template" background-color="#fff" outlined hide-details @change="master_selected"></v-select>
		</div>

		<div v-if="master_chosen">
			<div class="mt-1">
				<v-text-field background-color="#fff" dense hide-details outlined :label="lesson_noun+' Title'" v-model="edited_lesson.lesson_title"></v-text-field>
			</div>

			<div v-if="lesson_class!='template'" class="mt-3">
				<v-menu ref="lesson_date_menu" v-model="lesson_date_menu" :close-on-content-click="false" :return-value.sync="edited_lesson.lesson_date" transition="scale-transition" offset-y min-width="290px">
					<template v-slot:activator="{on}"><v-text-field background-color="#fff" outlined dense hide-details v-model="lesson_date_formatted" label="Lesson Date" append-icon="fas fa-calendar-day" @click:append="lesson_date_menu=true" readonly v-on="on"></v-text-field></template>
					<v-date-picker v-model="edited_lesson.lesson_date" no-title scrollable>
						<v-btn text color="primary" @click="no_lesson_date">No Lesson Date</v-btn>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="lesson_date_menu=false">Done</v-btn>
					</v-date-picker>
				</v-menu>
			</div>

			<!-- <div class="mt-2">
				<div v-if="false" class="mb-1 ml-1" @click.stop="student_description_showing=!student_description_showing" style="cursor:pointer"><v-icon color="indigo" style="margin-top:-2px" class="mr-2">fas {{student_description_showing?'fa-caret-down':'fa-caret-right'}}</v-icon><b class="k-lesson-title-intro">Student-Facing Lesson Overview</b> <span style="font-size:14px">({{student_description_word_count}})</span></div>
				<v-expand-transition><div v-show="student_description_showing"><froala-wrapper :config="editor_config()" v-model="edited_lesson.student_description" /></div></v-expand-transition>
			</div> -->

			<!-- STANDARDS -->
			<div class="mt-2">
				<div class="d-flex align-center lp_unit_editor_standard_div">
					<div class="mb-1 ml-1" @click.stop="standards_showing=!standards_showing" style="cursor:pointer"><v-icon color="indigo" style="margin-top:-2px" class="mr-2">fas {{standards_showing?'fa-caret-down':'fa-caret-right'}}</v-icon><b class="k-lesson-title-intro">Standards and Elements</b><span style="font-size:14px">({{edited_lesson.standards.length}})</span></div>
					<v-spacer/>
					<v-btn v-show="standards_showing" class="mt-2" small color="#555" dark @click="align_to_standard(null)"><v-icon class="mr-1" small>fas fa-bullseye</v-icon>Edit Aligned Standards</v-btn>
				</div>

				<v-expand-transition><div v-show="standards_showing&&edited_lesson.standards.length>0" style="background-color:#fff; padding:2px 8px; border-radius:6px; margin-top:8px;">
					<draggable v-bind="drag_options" v-model="edited_lesson.standards" @end="drag_complete" class="d-flex flex-wrap my-1">
						<CASEItemBtn v-for="(s) in edited_lesson.standards" :key="s.identifier" :item="s" @click="align_to_standard(s)" @delete="remove_standard(s)" small show_move_icon show_delete_icon :outer_class="'ma-1'" />
					</draggable>
				</div></v-expand-transition>
			</div>

			<div class="mt-2">
				<div class="mb-1 ml-1" @click.stop="lesson_components_showing=!lesson_components_showing" style="cursor:pointer"><v-icon color="indigo" style="margin-top:-2px" class="mr-2">fas {{lesson_components_showing?'fa-caret-down':'fa-caret-right'}}</v-icon><b class="k-lesson-title-intro">Lesson Components</b></div>

				<!-- COMPONENTS -->
				<v-expand-transition><div v-show="lesson_components_showing"><div v-for="(component, ci) in edited_lesson.lesson_plan" :key="component.uuid" :class="'k-lesson-editor-component ' + ((component.lc_showing)?'k-lesson-component-showing':'')">
					<div class="k-lesson-component-title" @click="toggle_component(ci)">
						<div class="k-lesson-component-toggle-holder"><v-btn icon x-small color="indigo darken-2" @click.stop="toggle_component(ci)"><v-icon>fas {{component.lc_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn></div>
						<div class="k-lesson-component-title-text" :class="'k-lesson-component-title-text-'+component.lc_weight">{{component.lc_title}} <span style="font-size:14px; font-weight:normal">({{component_content_count[ci]}})</span></div>
						<div v-if="component.lc_weight!=0" class="k-lesson-component-title-weight" :style="'flex-basis:' + component.lc_weight + '%'">{{component.lc_weight}}%</div>
					</div>
					<div v-show="component.lc_showing">
						<div class="k-lesson-component-guidance my-1 ml-2" v-html="component.lc_guidance"></div>

						<!-- COMPONENT PART -->
						<div v-for="(component_part, cpi) in component.lc_parts" :key="cpi" class="ma-2" :class="component_part.lcp_being_edited?'k-lesson-component-part-being-edited':''">
							<div class="k-lesson-component-part-header" :class="component_part.lcp_being_edited?'amber':''">
								<div v-if="component.lc_parts.length>1" class="black--text">Part {{cpi+1}}</div>
								<div v-if="component_part.lcp_title" class="ml-2 black--text" v-html="component_part.lcp_title"></div>
								<v-btn v-if="component_part.lcp_being_edited" x-small text color="primary" class="k-tight-btn ml-1" @click="edit_component_part_title(ci, cpi)"><span v-if="!component_part.lcp_title" class="mr-2">Add Part Title</span><v-icon x-small>fas fa-pencil</v-icon></v-btn>
								<v-spacer />
								<v-btn v-show="component_part.lcp_being_edited&&component.lc_parts.length>1" small icon color="red darken-4" class="mr-1" @click="delete_component_part(ci, cpi)"><v-icon small>fas fa-trash-alt</v-icon></v-btn>
								<v-btn v-show="component_part.lcp_being_edited" small icon color="primary" @click="edit_component_part(ci, cpi)"><v-icon>fas fa-check-circle</v-icon></v-btn>

								<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!component_part.lcp_being_edited" v-visible="cpi>0" small icon color="primary" @click="move_component_part(ci, cpi, 'up')"><v-icon small style="transform:rotate(-90deg)">fas fa-circle-play</v-icon></v-btn></template>Move lesson part UP</v-tooltip>
								<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!component_part.lcp_being_edited" v-visible="cpi<component.lc_parts.length-1" small icon color="primary" @click="move_component_part(ci, cpi, 'down')"><v-icon small style="transform:rotate(90deg)">fas fa-circle-play</v-icon></v-btn></template>Move lesson part DOWN</v-tooltip>

								<v-btn v-show="!component_part.lcp_being_edited" small text color="primary" @click="edit_component_part(ci, cpi)">Edit <v-icon small class="ml-1">fas fa-edit</v-icon></v-btn>
							</div>
							<div v-if="!component_part.lcp_being_edited" class="fr-view" v-html="U.render_latex(component_part.lcp_teacher_content)"></div>
							<div v-if="component_part.lcp_being_edited"><froala-wrapper :config="editor_config()" v-model="component_part.lcp_teacher_content" /></div>
						</div>

						<div class="text-center mt-3 mb-3"><v-btn small color="primary" @click="add_component_part(ci)"><v-icon small class="mr-1">fas fa-plus</v-icon>Add Lesson Component Part</v-btn></div>

						<!-- <div v-if="component.lc_default_content" class="k-lesson-component-default-content mt-2 ml-2 mb-2">
							<div class="float-left mr-2 grey--text text--darken-2"><b>“Looks like…”</b></div>
							<div v-html="component.lc_default_content"></div>
						</div> -->
					</div>
				</div></div></v-expand-transition>

				<!-- RESOURCES -->
				<div class="mt-2">
					<div class="d-flex align-center">
						<div class="mb-1 ml-1" @click.stop="resources_showing=!resources_showing" style="cursor:pointer"><v-icon color="indigo" style="margin-top:-2px" class="mr-2">fas {{resources_showing?'fa-caret-down':'fa-caret-right'}}</v-icon><b class="k-lesson-title-intro">Lesson Resources</b><span style="font-size:14px">({{edited_lesson.resources.length}})</span></div>
						<v-spacer/>
						<v-btn v-show="resources_showing&&edited_lesson.resources.length>1" small class="ml-2" color="#666" dark @click="resource_reorder_open=true"><v-icon x-small class="mr-1">fas fa-arrows-alt-v</v-icon>Reorder</v-btn>

						<v-btn v-show="resources_showing" small class="ml-2" color="#555" dark @click="create_resource_start"><v-icon small class="mr-1">fas fa-plus-circle</v-icon>Add Resource</v-btn>
					</div>
					<v-expand-transition><div v-show="resources_showing&&edited_lesson.resources.length>0" class="k-lesson-resource-collection mt-3" style="background-color:#fff; padding:2px 8px; border-radius:6px; margin-top:8px;">
						<div v-for="(resource) in edited_lesson.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
							<ResourceCollectionItem
								:item="resource"
								:enable_resource_search="false"
								:enable_reorder="false"
								:lp_context="lp" :unit_context="unit"
								@edit_item_saved="edit_item_saved"
								@edit_item_cancel="edit_item_cancel"
								@remove_item="remove_item"
							/>
						</div>
					</div></v-expand-transition>
				</div>

			</div>
		</div>
	</div>
	<div class="px-3 py-2 k-lesson-editor-buttons">
		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-if="course_code" v-on="on" class="mr-2" fab x-small dark color="secondary" @click="$emit('shift_lesson')"><v-icon small>fas {{lesson_shifted?'fa-angles-left':'fa-angles-right'}}</v-icon></v-btn></template>Shift Lesson</v-tooltip>
		<v-btn color="secondary" class="k-tight-btn" @click="edit_lesson_cancel"><v-icon small class="mr-2">fas fa-times</v-icon>{{is_new_lesson?'Cancel':'Close Editor'}}&nbsp;</v-btn>
		<v-spacer/> <!-- note: it causes complications when we save a new lesson without immediately closing it, so take away this option -->
		<v-btn v-if="master_chosen&&!is_new_lesson" color="primary" class="ml-3 k-tight-btn" @click="save_lesson"><v-icon small class="mr-2">fas fa-save</v-icon>Save&nbsp;</v-btn>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_and_close"><v-icon small class="mr-2">fas fa-check</v-icon>Save and Close Editor</v-btn>
	</div>

	<!-- resource reorderer -->
	<v-dialog v-if="resource_reorder_open" v-model="resource_reorder_open" max-width="680" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900">Reorder Resources</b></v-card-title>
			<v-card-text class="pt-3" style="background-color:#eee">
				<draggable v-bind="{animation:300}" v-model="edited_lesson.resources" @end="save_lesson">
					<div v-for="(resource) in edited_lesson.resources" :key="resource.resource_id" style="border:1px solid #333; padding:4px; margin:4px 0; background-color:#fff; border-radius:6px; cursor:move" class="d-flex align-center">
						<div class="mx-2 k-lesson-editor-resource-reorderer-handle"><v-icon small>fas fa-arrows-alt-v</v-icon></div>
						<div style="font-size:14px;" v-html="resource.description"></div>
					</div>
				</draggable>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="resource_reorder_open=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<ResourceSearch v-if="show_search" 
		:dialog_title="`Search for Resource${resource_search_home_lesson?'':'s'} to Add to Lesson`"
		:item_types="['resources']"
		:home_lesson="resource_search_home_lesson"
		:home_unit="unit"
		:home_collection="lp"
		:allow_add_from_home="true"
		:max_items="resource_search_home_lesson?1:0"
		:existing_resources="resource_search_home_lesson?[]:edited_lesson.resources"
		:adding_to_lesson="true"
		@add_items_from_search="add_items_from_search" @dialog_cancel="search_cancel" 
	/> <!-- if we're adding to froala text, we want to give the user the option of choosing resources from this lesson, and we don't want to show existing resources -->

</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import FroalaResourcesMixin from '../../js/FroalaResourcesMixin'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	name: 'LessonEditor',
	components: { 
		ResourceCollectionItem: () => import('../resources/ResourceCollectionItem'),
		ResourceSearch: () => import('../resources/ResourceSearch'),
		draggable, CASEItemBtn,  },
	mixins: [FroalaResourcesMixin],
	props: {
		original_lesson: { required: true },	// if 'new' we're creating a new lesson
		lesson_class: { type: String, required: false, default() { return '' }},	// 'teacher' or 'template'; masters have their own editor
		course_code: { type: String, required: false, default() { return '' }},
		lp_unit_id: { type: Number, required: false, default() { return 0 }},
		lesson_shifted: { type: Boolean, required: false, default() { return false }}
	},
	data() { return {
		edited_lesson: null,
		master_chosen: false,
		selected_master_id: -1,
		lesson_master: null,
		resource_being_edited: false,
		standards_showing: false,
		show_search: false,
		resource_search_home_lesson: null,

		student_description_showing: false,
		lesson_components_showing: true,
		resources_showing: false,
		standards_showing: false,

		resource_reorder_open: false,

		lesson_date_menu: false,

		stringified_original_lesson: '',

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		}
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'lesson_masters', 'default_lesson_master']),
		...mapGetters([]),
		lesson_course_code() {
			if (this.original_lesson.course_code) return this.original_lesson.course_code
			else return this.course_code
		},
		lesson_lp_unit_id() {
			if (this.original_lesson.lp_unit_id) return this.original_lesson.lp_unit_id
			else return this.lp_unit_id
		},
		lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.lesson_course_code) },
		unit() {
			if (!this.lp || this.lesson_lp_unit_id == 0) return null
			return this.lp.units.find(x=>x.lp_unit_id == this.lesson_lp_unit_id)
		},
		subject() {
			if (!this.lp) {
				// console.log('LessonEditor: no lp, so can’t get subject')
				return null
			}
			return this.lp.subject
		},
		case_framework_identifier() { return this.$store.state.subjects[this.subject]?.framework_identifier },	// may return null
		case_framework() { return this.$store.state.case_frameworks[this.case_framework_identifier] },
		lesson_noun() {
			if (this.lesson_class == 'master') return 'Lesson Master'	// not used here
			else if (this.lesson_class == 'template') return 'Template Lesson'
			else return 'Lesson'
		},
		is_new_lesson() { return this.original_lesson == 'new' || this.original_lesson.lesson_id == 0  },
		show_master_select() {
			if (this.lesson_class == 'master') return false		// not used here
			if (!this.is_new_lesson) return false
			if (this.default_lesson_master) return false
			return true
		},
		master_select_items() {
			let arr = [{value: '-1', text:'-- SELECT A MASTER LESSON TEMPLATE --'}]
			for (let lm of this.lesson_masters) {
				arr.push({ value: lm.lesson_id, text: lm.lesson_title })
			}
			return arr
		},
		lesson_date_formatted() {
			if (empty(this.edited_lesson)) return ''
			if (empty(this.edited_lesson.lesson_date)) return ''
			let d = new Date(this.edited_lesson.lesson_date + 'T00:00:00')
			return date.format(d, 'ddd MMM D')	// Tue Jan 9
		},
		student_description_word_count() {
			let wc = U.word_count(U.html_to_text(this.edited_lesson.student_description))
			return sr('$1 $2', wc, U.ps('word', wc))
		},
		component_content_count() {
			let arr = []
			for (let comp of this.edited_lesson.lesson_plan) {
				let wc = 0
				for (let part of comp.lc_parts) {
					wc += U.word_count(U.html_to_text(part.lcp_teacher_content))
				}

				let text = ''
				if (comp.lc_parts.length > 1) text += sr('$1 $2; ', comp.lc_parts.length, U.ps('part', comp.lc_parts.length))
				text += sr('$1 $2', wc, U.ps('word', wc))

				arr.push(text)
			}
			return arr
		},
		chosen_standard_identifiers() {
			if (!this.edited_lesson) return []

			let arr = []
			for (let s of this.edited_lesson.standards) {
				arr.push(s.identifier)
			}
			return arr
		},
	},
	watch: {
		chosen_standard_identifiers() {
			// console.log('chosen_standard_identifiers updated! ' + this.chosen_standard_identifiers.length)
			// if we're showing vapp.case_tree_component and the standard_identifiers get updated, push the change...
			if (vapp.case_tree_component && vapp.case_tree_component.set_chosen_items) {
				vapp.case_tree_component.set_chosen_items(this.chosen_standard_identifiers)
			}
		},
		master_chosen(val) {
			// when master has been chosen, scroll up to reveal the rest of the template
			// Not anymore -- now we show the editor in a dialog
			// if (val) {
			// 	setTimeout(x=>{
			// 		this.$vuetify.goTo(this.$el, {offset:96})
			// 	}, 50)
			// }
		},
		'edited_lesson.lesson_date'(val) {
			if (this.$refs.lesson_date_menu) this.$refs.lesson_date_menu.save(this.edited_lesson.lesson_date)
		},
	},
	created() {
	},
	mounted() {
		// for an existing lesson, create edited_lesson now, based on original_lesson and lesson_master (if it has a master)
		if (!this.is_new_lesson) {
			// get the lesson_master if one was specified and we're not editing a master
			if (this.original_lesson.lesson_master_id > 0) {
				this.lesson_master = this.lesson_masters.find(x=>x.lesson_id==this.original_lesson.lesson_master_id)
			}

			this.edited_lesson = new Lesson(this.original_lesson, this.lesson_master)
			this.master_chosen = true

		} else if (this.default_lesson_master) {
			// else there is a default_lesson_master for the site, choose that master
			this.master_chosen = true
			this.lesson_master = this.default_lesson_master
			this.create_edited_lesson()
		} else {
			// this shouldn't happen
			this.$alert('LessonEditor error: no default_lesson_master')
		}

		// always copy through the value of some properties from original_lesson
		this.edited_lesson.agency_sanctioned = this.original_lesson.agency_sanctioned

		// for each component of the lesson plan
		for (let c of this.edited_lesson.lesson_plan) {
			// if the component has exactly 1 part, set it to be open for editing
			// (if the component has more than 1 part, let the user choose which part to open)
			if (c.lc_parts.length == 1) {
				c.lc_parts[0].lcp_being_edited = true
			}
		}

		this.stringified_original_lesson = JSON.stringify(this.edited_lesson.copy_for_save())

		// this used by the standards chooser hide_fn
		vapp.lesson_editor_component = this
	},
	methods: {
		toggle_component(ci, val) {
			if (typeof(val) != 'boolean') val = !this.edited_lesson.lesson_plan[ci].lc_showing
			this.edited_lesson.lesson_plan[ci].lc_showing = val
		},

		editor_config(text, inline) {
			let o = {
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
				zIndex: 1000,	// needed for to make table menus show up properly
			}
			let config = U.get_froala_config(o)

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			config.toolbarButtons.moreRich.buttonsVisible += 1
			// and remove the 'insertHR' btn, if there
			let i = config.toolbarButtons.moreRich.buttons.findIndex(x=>x=='insertHR')
			if (i > -1) {
				config.toolbarButtons.moreRich.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreRich.buttonsVisible-1)) config.toolbarButtons.moreRich.buttonsVisible -= 1
			}
			// also remove the 'paragraphFormat' btn from the moreMisc buttons
			i = config.toolbarButtons.moreMisc.buttons.findIndex(x=>x=='paragraphFormat')
			if (i > -1) {
				config.toolbarButtons.moreMisc.buttons.splice(i, 1)
				if (i <= (config.toolbarButtons.moreMisc.buttonsVisible-1)) config.toolbarButtons.moreMisc.buttonsVisible -= 1
			}

			return config
		},

		create_edited_lesson() {
			// this.edited_lesson will be null if this is being called from mounted, or may be an existing object if called from master_selected
			this.edited_lesson = new Lesson(this.edited_lesson, this.lesson_master)

			// set lesson_master_id
			if (this.lesson_master) {
				this.edited_lesson.lesson_master_id = this.lesson_master.lesson_id
			}

			// make sure we have at least one part in each component
			for (let comp of this.edited_lesson.lesson_plan) {
				if (comp.lc_parts.length == 0) {
					comp.lc_parts.push(new Lesson_Component_Part())
				}
			}

			// set the new lesson's course_code and lp_unit_id to what we received
			this.edited_lesson.course_code = this.course_code
			this.edited_lesson.lp_unit_id = this.lp_unit_id
		},

		master_selected() {
			if (this.selected_master_id == -1) return

			// this will be called if we're creating a new non-master lesson, once the user has chosen which master to use
			// get the lesson_master (might be null; that's OK)
			this.lesson_master = this.lesson_masters.find(x=>x.lesson_id == this.selected_master_id)
			this.master_chosen = true
			this.create_edited_lesson()
		},

		no_lesson_date() {
			this.edited_lesson.lesson_date = ''
			this.lesson_date_menu = false
		},

		add_component() {
			this.edited_lesson.lesson_plan.push(new Lesson_Component())
		},

		delete_component(uuid) {
			this.edited_lesson.lesson_plan.splice(this.edited_lesson.lesson_plan.findIndex(x=>x.uuid==uuid), 1)
		},

		add_component_part(ci) {
			// create new part with lcp_showing and lcp_being_edited set to true, so it'll be open for editing right away
			let p = new Lesson_Component_Part({lcp_showing:true, lcp_being_edited:true})
			this.edited_lesson.lesson_plan[ci].lc_parts.push(p)
		},

		edit_component_part(ci, cpi) {
			// if we were already editing, save and stop
			if (this.edited_lesson.lesson_plan[ci].lc_parts[cpi].lcp_being_edited) {
				this.edited_lesson.lesson_plan[ci].lc_parts[cpi].lcp_being_edited = false
				this.save_lesson()
			} else {
				this.edited_lesson.lesson_plan[ci].lc_parts[cpi].lcp_being_edited = true
			}
		},

		move_component_part(ci, cpi, direction) {
			// this will only be called if no part is being edited
			let temp = this.edited_lesson.lesson_plan[ci].lc_parts[cpi]
			this.edited_lesson.lesson_plan[ci].lc_parts.splice(cpi, 1)
			if (direction == 'up') {
				this.edited_lesson.lesson_plan[ci].lc_parts.splice(cpi-1, 0, temp)
			} else {
				this.edited_lesson.lesson_plan[ci].lc_parts.splice(cpi+1, 0, temp)
			}
			this.save_lesson()
		},

		edit_component_part_title(ci, cpi) {
			this.$prompt({
				title: 'Edit Part Title',
				text: 'Enter the title to use for this lesson component part:',
				initialValue: this.edited_lesson.lesson_plan[ci].lc_parts[cpi].lcp_title,
				acceptText: 'Save',
			}).then(title => {
				this.edited_lesson.lesson_plan[ci].lc_parts[cpi].lcp_title = $.trim(title)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_component_part(ci, cpi, confirmed) {
			let p = this.edited_lesson.lesson_plan[ci].lc_parts[cpi]
			if (p.has_content() && confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'Are you sure you want to delete this lesson component part?',
				    acceptText: 'Delete',
					acceptColor: 'red',
				}).then(y => {
					this.delete_component_part(ci, cpi, true)
				}).catch(n=>{console.log(n)}).finally(f=>{})

				return
			}

			this.edited_lesson.lesson_plan[ci].lc_parts.splice(cpi, 1)
		},

		remove_item(resource, confirmed) {
			// check to see if the resource is anywhere in the text of the lesson (unless already confirmed)
			if (!confirmed) {
				let found = 0
				let position_string
				for (let ci = 0; ci < this.edited_lesson.lesson_plan.length; ++ci) {
					let comp = this.edited_lesson.lesson_plan[ci]
					for (let cpi = 0; cpi < comp.lc_parts.length; ++cpi) {
						if (comp.lc_parts[cpi].lcp_teacher_content.indexOf(resource.resource_id) > -1) {
							++found
							position_string = sr('<nobr>“$1”', this.edited_lesson.lesson_plan[ci].lc_title)
							if (this.edited_lesson.lesson_plan[ci].lc_parts.length > 1) position_string += ', part ' + (cpi+1)
							position_string += '</nobr>'
						}
					}
				}

				if (this.edited_lesson.student_description.indexOf(resource.resource_id) > -1) {
					++found
					position_string = '“Student-Facing Lesson Overview”'
				}

				if (found) {
					let found_string = (found==1) ? '' : 'at least ' + found + ' times'

					this.$confirm({
					    title: 'Are you sure?',
					    text: sr('This resource is linked $1 from your Lesson text, in the $2 portion of the Lesson. Are you sure you want to remove the resource? If you do so, the resource link(s) will also be removed from the Lesson Component(s).', found_string, position_string),
					    acceptText: 'Remove Resource',
						acceptColor: 'red',
						dialogMaxWidth: 600
					}).then(y => {
						this.remove_item(resource, true)
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}
			}

			// remove from lesson text			
			let re = new RegExp('<link class="k-lesson-component-resource-link[^>]+' + resource.resource_id + '[^>]+>', 'g')
			for (let comp of this.edited_lesson.lesson_plan) {
				for (let part of comp.lc_parts) {
					part.lcp_teacher_content = part.lcp_teacher_content.replace(re, '')
				}
			}
			this.edited_lesson.student_description = this.edited_lesson.student_description.replace(re, '')

			// splice the resource out
			let i = this.edited_lesson.resources.findIndex(x=>x==resource)
			if (i > -1) this.edited_lesson.resources.splice(i, 1)

			// then immediately save
			this.save_lesson(false)
		},

		create_resource_start() {
			// if this is initiated from the froala editor, show the user the option of inserting resources from the lesson
			if (this.fco) this.resource_search_home_lesson = this.edited_lesson

			// show the search dialog
			this.show_search = true
		},

		add_items_from_search(items) {
			// go through each item...
			for (let item of items) {
				let updated_resource = new Resource(item.value)

				// add to lesson resources, unless it's already there
				if (!this.edited_lesson.resources.find(x=>x.resource_id == updated_resource.resource_id)) {
					this.edited_lesson.resources.push(updated_resource)
				}

				// if we were adding to a lesson text, call insert_resource_selected, then close the interface on next tick
				if (this.fco) {
					this.insert_resource_selected(updated_resource)
					this.$nextTick(x=>this.search_cancel())
				}
			}

			this.save_lesson()
			this.$inform(`${items.length} ${U.ps('resource', items.length)} added`)
		},

		search_cancel() {
			// call insert_resource_cancel in case we were inserting a resource in a lesson component (note: this will not do anything if we just called insert_resource_selected
			this.insert_resource_cancel()
			this.resource_search_home_lesson = null
			this.show_search = false
		},

		// this is called from ResourceEditor, via ResourceCollectionItem
		edit_item_saved(args) {
			let updated_resource = new Resource(args.updated_resource)

			// the resource editor will have saved the resource to the db; splice it to edited_lesson.resources
			let index = this.edited_lesson.resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
			if (index == -1) {
				// shouldn't happen
				this.$alert('error: couldn’t find index of edited resource')
				return
			} else {
				this.edited_lesson.resources.splice(index, 1, updated_resource)
				// the lesson data won't change, so we have to update the original_unit too
				if (!this.is_new_lesson) this.original_lesson.resources.splice(index, 1, new Resource(updated_resource))
			}

			// save lesson edits right away whenever a new resource is updated
			this.save_lesson()
		},

		edit_item_cancel(args) {
			// we don't have to do anything here
		},

		reorder_resources() {
			this.save_lesson()
			this.resource_reorder_open = false
		},

		///////////////////////////////////
		// Standards
		align_to_standard(start_item) {
			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if the lesson has a case_framework_identifier, send it
				if (this.edited_lesson.case_framework_identifier) data.framework_identifier = this.edited_lesson.case_framework_identifier

				// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// if the lesson has a course_case_identifier, send it
				if (this.edited_lesson.course_case_identifier) data.item_identifier = this.edited_lesson.course_case_identifier

				// HACK: trace up through parents looking for a course_case_identifier value (e.g. in CourseView); if found, use it as item_identifier
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current lesson standards as selected items
			if (this.edited_lesson.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.edited_lesson.standards) data.selected_items.push(standard.identifier)
			}

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($(vapp.lesson_editor_component?.$el).is(':visible') == false) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.edited_lesson.standards.findIndex(o=>o.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.edited_lesson.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier. use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
							let o = new CASE_Item(aligned_item.cfitem, aligned_item.framework_identifier)
							this.edited_lesson.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
						
						// save immediately, unless this is a new lesson
						if (!this.is_new_lesson) this.save_lesson('no_spinner')
					})
				})
			})
		},

		remove_standard(s, confirmed) {
			console.log(s)
			let i = this.edited_lesson.standards.findIndex(o=>o.identifier==s.identifier)
			if (i > -1) {
				if (!this.confirmed_standard_delete && !confirmed) {
					this.$confirm({
						title: 'Remove Standard Alignment',
						text: 'Are you sure you want to remove this standard alignment from the Lesson Plan?',
						acceptText: 'Remove',
						acceptColor: 'red',
					}).then(y => {
						this.remove_standard(s, true)
						this.confirmed_standard_delete = true
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				this.edited_lesson.standards.splice(i, 1)
				// save immediately, unless this is a new lesson
				if (!this.is_new_lesson) this.save_lesson('no_spinner')
			}
		},

		drag_complete(evt) {
			// standards will have been reordered; just need to save
			if (!this.is_new_lesson) this.save_lesson('no_spinner')
		},

		///////////////////////////////////
		save_and_close() {
			this.save_lesson('and_close')
		},

		save_lesson(flag) {
			this.edited_lesson.lesson_title = $.trim(this.edited_lesson.lesson_title)
			if (empty(this.edited_lesson.lesson_title)) {
				this.$alert('You must enter a title for the lesson.')
				return
			}

			// if any component ends with an empty part, trim that empty part out
			for (let comp of this.edited_lesson.lesson_plan) {
				if (comp.lc_parts.length > 0) {
					let last_part = comp.lc_parts[comp.lc_parts.length-1]
					if (!last_part.has_content()) {
						comp.lc_parts.pop()
					}
				}
			}

			if (flag != 'no_spinner') U.loading_start()
			U.ajax('save_lesson', {
				user_id: this.user_info.user_id,
				lesson_class: this.lesson_class,
				lesson_data: JSON.stringify(this.edited_lesson.copy_for_save())
			}, result=>{
				if (flag != 'no_spinner') U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in ajax call'); vapp.ping(); return;
				}

				// updated data will be returned; emit it to the parent component, along with the flag
				// (the flag might be 'and_close', in which case the parent should take care of closing the editor)
				this.$emit('edit_lesson_saved', {updated_lesson: result.updated_lesson, flag:flag, edited_lesson:this.edited_lesson})	// pass edited_lesson so we can preserve *_showing values

				// save stringified original lesson
				this.stringified_original_lesson = JSON.stringify(this.edited_lesson.copy_for_save())
			});
		},

		edit_lesson_cancel(confirmed) {
			if (confirmed !== true && this.stringified_original_lesson != JSON.stringify(this.edited_lesson.copy_for_save())) {
				this.$confirm({
				    text: 'You appear to have made changes. Are you sure you want to close the lesson editor with out saving these changes?',
					acceptText: 'Don’t Save',
				}).then(y => {
					U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.edited_lesson.lesson_id})
					this.edit_lesson_cancel(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}
			U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.edited_lesson.lesson_id})
			this.$emit('edit_lesson_cancel')
		},
	}
}
</script>

<style lang="scss">
// this class, or something like it, should be applied on the div that includes the LessonEditor component
.k-lesson-edit-outer {
	position:relative;
	margin-top:8px;
	padding:8px 8px;
	background-color:#eee;
	border-radius:8px;
}

.k-lesson-edit {
	.v-input {
		font-size:15px;
	}

	.k-lp-editor-standard-resource {
		padding:5px;
		margin:10px 0;
		border-radius:4px;
		background-color:#eee;
	}

	.k-lp-editor-standard-description {
		flex:1 1 auto;
		cursor:pointer;
		font-size:14px;
		line-height:18px;
	}
}

.k-lesson-editor-component {
	border:1px solid $v-amber-darken-2;
	border-radius:7px;
	margin-top: 8px;
	font-size:14px;
	color:#666;
	background-color:#fff;
}

.k-lesson-component-part-being-edited {
	background-color:$v-amber-lighten-4;
	padding:0 8px 8px 8px;
	border-radius:8px;

	.k-lesson-component-part-header {
		margin-left:-8px;
		margin-right:-8px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.k-lesson-editor-component-master {
	border:1px solid #ccc;
	border-radius:8px;
	padding:12px;
	background-color:#f8f8f8;
	margin: 12px;
	font-size:14px;
	color:#666;
}

.k-lesson-editor-buttons {
	border-top:1px solid #999;
	background-color:#eee;
	position:absolute;
	left:0;
	bottom:0px;
	border-radius:0 0 8px 8px;
	width:100%;
	display:flex;
	align-items:center;
	z-index:1001;
}

.v-dialog .k-lesson-editor-buttons {
	background-color:#fff;
	margin-bottom:-8px;
	padding-bottom:8px;
}

// this fixes things so that the "full screen" button shows up properly, at least in the lesson editor
.fr-toolbar .fr-expanded {
	width:calc(100% + 1px)!important;
	margin-left:-1px;
}
</style>
