<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template><div><v-dialog v-model="dialog_open" max-width="1200" persistent scrollable>
<v-card>
<v-card-title class="pt-2" style="border-bottom:1px solid #999">
	<b v-html="dialog_title"></b>
	<v-spacer></v-spacer>
	<v-btn v-if="collection_type=='my'" color="pink darken-3" dark small class="mr-8 k-tight-btn" @click="additional_my_options_showing=!additional_my_options_showing"><v-icon small class="mr-2">fas fa-gear</v-icon>{{additional_my_options_showing?'Hide':'Show'}} Additional Options</v-btn>
	<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-show="additional_my_options_showing" v-on="on" class="mr-2" color="secondary">Advanced Editor Tools</v-btn></template>
		<v-list min-width="250">
			<v-list-item @click="import_terms_btn_clicked"><v-list-item-title>Import Terms and Units from MS Word</v-list-item-title></v-list-item>
			<v-list-item @click="import_cc_btn_clicked"><v-list-item-title>Import Resources from Thin Common Cartridge</v-list-item-title></v-list-item>
		</v-list>
	</v-menu>
	<v-btn color="secondary" @click="cancel_editor" class="mr-2"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
	<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
</v-card-title>
<v-card-text class="ma-0 pt-0 pb-3 px-3" style="font-size:16px; color:#000;">

<div class="k-lp-editor-wrapper">
	<div class="k-lp-editor-top-data">
		<div class="d-flex mb-4 align-center">
			<v-text-field background-color="#fff" style="font-weight:bold" hide-details outlined :label="collection_type=='course'?'Course Title (required)':'Collection Title (required)'" v-model="title" @change="title_changed"></v-text-field>
			<v-text-field v-if="collection_type=='course'" background-color="#fff" style="font-weight:bold;max-width:250px" class="ml-4" hide-details outlined label="Course Code" v-model="course_code"></v-text-field>
			<!-- <v-text-field v-if="is_repo_or_pd" background-color="#fff" style="max-width:320px" class="ml-4" hide-details outlined label="Repository Category" v-model="repo_category"></v-text-field> -->
			<div v-if="is_repo_or_pd" class="d-flex">
				<v-select v-model="repo_category" :items="repo_dropdown" @change="repo_category_changed" label="Repo Category" outlined background-color="#fff" hide-details class="k-lp-subject-menu" :menu-props="{maxHeight:700}"></v-select>
			</div>
		</div>

		<div v-if="additional_my_options_showing" class="mb-2"><b>Grade range, subject, and other collection metadata:</b><span v-if="collection_type!='course'" class="ml-2">(optional)</span></div>
		<div v-if="additional_my_options_showing" class="d-flex mb-4">
			<v-select v-model="grade_low" :items="grades_low" @change="grade_low_changed" label="Grade Low" dense outlined background-color="#fff" hide-details class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-select v-model="grade_high" :items="grades_high" @change="grade_high_changed" label="Grade High" dense outlined background-color="#fff" hide-details class="k-lp-grade-menu" :menu-props="{maxHeight:700}"></v-select>
			<v-select v-model="subject" :items="subjects" label="Subject" dense outlined background-color="#fff" hide-details class="k-lp-subject-menu" :menu-props="{maxHeight:700}" @change="subject_changed"></v-select>
			<!-- <v-select v-model="color" :items="color_select" label="Color" dense outlined background-color="#fff" hide-details class="k-lp-color-menu"></v-select> -->
			<span style="margin-left: 10px"><ColorPicker :color="color" :title="title" :subject="subject" @update_color="update_color"/></span>
			<!-- <div class="k-lp-color-swatch" :class="color_swatch_class"></div> -->
			<!-- <div class="k-lp-color-swatch" :style="`background-color: ${picker_color}`"></div> -->
			<!-- "my" collections are always active -->
			<v-checkbox v-if="collection_type!='my'" class="ml-4 pt-0 mt-2" :label="'Active'" v-model="active" hide-details></v-checkbox>
			<v-checkbox v-if="is_repo" class="ml-4 pt-0 mt-2" :label="'Subscription Only'" v-model="subscription_only" hide-details></v-checkbox>
		</div>

		<div v-if="additional_my_options_showing" class="d-flex mb-4 align-start">
			<div style="flex:1 0 50%">
				<div class="mb-1"><b>“Home” Standards Framework:</b><span class="ml-2">(optional)</span></div>
				<div v-if="!changing_subject_case_title" class="d-flex mr-4">
					<v-btn small class="mr-2" color="primary" @click="changing_subject_case_title=true">Change</v-btn>
					<div v-html="subject_case_title"></div>
				</div>
				<div v-if="changing_subject_case_title" class="d-flex">
					<v-select v-model="subject_case_identifier" :items="subject_frameworks" label="Subject Framework" dense outlined background-color="#fff" hide-details class="k-lp-subject-menu" @change="course_case_identifier=''"></v-select>
					<v-btn small class="ml-2" color="primary" @click="changing_subject_case_title=false">Done</v-btn>
				</div>
			</div>
			<div style="flex:1 0 50%" v-show="subject_case_identifier&&subject_case_identifier!='none'">
				<div class="mb-1"><b>Home “Branch” in Standards Framework:</b> (optional)</div>
				<div class="d-flex">
					<div v-if="course_case_title" v-html="course_case_title" class="mr-2"></div>
					<v-btn small color="primary" @click="choose_course_case_identifier">{{course_case_identifier?'Change':'Choose'}}</v-btn>
				</div>
			</div>
		</div>

		<div class="mb-4"><div class="mb-1"><b>Content Collection Description:</b><span class="ml-2">(optional)</span></div>
			<div class="k-collection-description-froala-wrapper-wrapper"><froala-wrapper :config="editor_config('')" v-model="description" /></div>
		</div>

		<div v-if="resource_collection_ids.length > 0" class="mt-3 text-center">
			<v-btn v-for="(resource_collection_id, i) in resource_collection_ids" :key="i" class="ma-1" style="letter-spacing:0; text-transform:none;" color="secondary" small @click="clear_resource_collection(i)">Clear Resource Collection “{{resource_collection_ids[i]}}” from LP</v-btn>
		</div>
	</div>

	<div v-if="additional_my_options_showing" class="k-lp-editor-terms">
		<div class="d-flex align-center">
			<h3 class="ml-3">Unit Arrangement:</h3>
			<div class="ml-4">
				<v-btn-toggle dense active-class="k-toggle-btn-active-class blue darken-4" class="k-toggle-btn" v-model="lp_layout" mandatory>
					<v-btn style="width:240px" small class="k-tight-btn k-nocaps-btn" light value="tree" @click.stop=""><v-icon small :color="lp_layout=='tree'?'white':'black'" class="mr-2">fas fa-arrows-up-down</v-icon>Vertical (Repository)</v-btn>
					<v-btn light value="map" small class="k-tight-btn k-nocaps-btn" @click.stop=""><v-icon small :color="lp_layout=='map'?'white':'black'" class="mr-2">fas fa-arrows-left-right</v-icon>Horizontal (Scope & Sequence)</v-btn>
				</v-btn-toggle>
			</div>
			<v-spacer/>
		</div>
		<div v-show="lp_layout=='map'&&additional_my_options_showing" class="mt-4">
			<div class="mx-3 pb-2 pt-1 px-2" style="background-color:#fff; border-radius:8px;"><v-checkbox class="mt-0" :label="'Arrange units by Terms'" v-model="use_terms" hide-details></v-checkbox></div>
			<div v-if="!use_terms" class="mt-4 mx-3"><v-text-field  background-color="#fff" hide-details outlined label="Label for unit scope & sequence (e.g. “Curriculum Map”)" v-model="unit_collection_title"></v-text-field></div>
			<h3 v-if="use_terms" class="ml-3 mt-4">Terms:</h3>
			<div v-if="use_terms" class="d-flex flex-wrap justify-left">
				<div v-for="(term, i) in terms" :key="i" class="k-lp-editor-term elevation-3">
					<div class="d-flex align-center">
						<v-text-field background-color="#fff" color="" style="font-weight:bold" hide-details outlined label="Term (e.g. “Quarter 1”)" v-model="term.title" @change="term_title_changed(term)"></v-text-field>
						<v-text-field background-color="#fff" class="ml-1" hide-details outlined label="Weeks" style="max-width:70px" v-model="term.duration"></v-text-field>
						<v-btn icon x-small color="red lighten-2" class="ml-2" style="margin-right:-3px" @click="delete_term(term)"><v-icon>fas fa-times-circle</v-icon></v-btn>
					</div>
				</div>
				<v-spacer/>
				<div class="mt-4 text-center" style="width:100%">
					<v-btn color="#666" width="300" dark @click="create_new_term">Create New Term</v-btn>
				</div>
			</div>
		</div>
	</div>

	<div class="k-lp-editor-units">
		<div class="d-flex align-center">
			<h3 class="ml-3">Units: <span style="font-size:0.8em;font-weight:normal;">(top-level “sub-collections”)</span></h3>
			<v-spacer/>
			<v-checkbox v-if="additional_my_options_showing" class="mt-0 mr-8" :label="'Specify unit numbers (e.g. “Unit 1”, “Unit 2”, etc.)'" v-model="use_unit_numbers" hide-details></v-checkbox>
			<v-checkbox v-if="additional_my_options_showing" class="mt-0 mr-3" :label="'Specify unit time intervals (weeks)'" v-model="use_unit_intervals" hide-details></v-checkbox>
		</div>
		<div>
			<draggable class="d-flex flex-wrap justify-left" v-bind="drag_options" v-model="units">
				<div v-for="(unit, _) in units" class="k-lp-editor-unit elevation-2" :style="units.length==1?'width:50%':''">
					<div class="d-flex align-center">
						<div class="mr-3"><v-icon small color="#999" class="moveHandle">fas fa-arrows-alt</v-icon></div>
						<div style="flex:1 1 auto">
							<div v-if="use_unit_numbers||use_unit_intervals" class="d-flex mb-3 flex-fill">
								<v-text-field v-if="use_unit_numbers" background-color="#fff" hide-details outlined style="font-weight:bold" label="Number (e.g. “Unit 1”)" v-model="unit.display_number" @change="unit_number_changed(unit)"></v-text-field>
								<v-text-field v-if="use_unit_intervals" background-color="#fff" class="mx-1" hide-details outlined label="Weeks" style="max-width:80px" v-model="unit.duration"></v-text-field>
							</div>
							<v-text-field background-color="#fff" hide-details outlined :label="units.length==1?'Unit Title (optional for a single-unit collection)':'Unit Title'" v-model="unit.title"></v-text-field>
						</div>
						<div v-show="units.length>1" class="ml-3" style="flex:0 0 20px">
							<v-btn icon x-small color="red lighten-2" @click="delete_unit(unit)"><v-icon>fas fa-times-circle</v-icon></v-btn><br>
							<!-- <v-btn icon x-small color="#666" @click="move_unit(unit,'up')"><v-icon>fas fa-arrow-circle-up</v-icon></v-btn><br>
							<v-btn icon x-small color="#666" @click="move_unit(unit,'down')"><v-icon>fas fa-arrow-circle-down</v-icon></v-btn> -->
						</div>
					</div>
				</div>
			</draggable>
			<v-spacer/>

			<div class="mt-4 text-center" style="width:100%">
				<v-btn color="#666" dark width="280" class="mx-1" @click="create_new_unit()">Create New Unit</v-btn>
				<v-btn v-if="collection_type=='course'" color="#666" dark width="280" class="mx-1" @click="create_new_unit_from_standard()">Create Unit(s) from Standard</v-btn>
			</div>

			<!-- e.g. https://apsources.commongoodlt.com -->
			<div v-if="user_info.email.includes('commongoodlt')" class="mt-4 d-flex"><v-spacer/><div style="flex:0 0 350px"><v-text-field dense background-color="#fff" hide-details outlined label="Sparkl origin override (advanced setting)" v-model="sparkl_origin_override"></v-text-field></div><v-spacer/></div>
		</div>
	</div>

</div>
</v-card-text>
</v-card>

</v-dialog></div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import ColorPicker from '../utilities/ColorPicker.vue'
// import FroalaWrapper from '../froala/FroalaWrapper'

export default {
	components: { draggable, ColorPicker },
	props: {
		learning_progression: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		import_terms_showing: false,
		title: this.learning_progression.title,
		lp_layout: this.learning_progression.lp_layout,
		updated_at: this.learning_progression.updated_at,
		course_code: this.learning_progression.course_code,
		subject_case_identifier: this.learning_progression.subject_case_identifier,
		course_case_identifier: this.learning_progression.course_case_identifier,
		description: this.learning_progression.description,
		grade_low: this.learning_progression.grade_low,
		grade_high: this.learning_progression.grade_high,
		subject: this.learning_progression.subject,
		color: this.learning_progression.color,
		active: (this.learning_progression.active == 'yes'),
		subscription_only: this.learning_progression.subscription_only,
		subscription_code: this.learning_progression.subscription_code,
		repo_category: this.learning_progression.repo_category,
		terms: [],	// copied below
		units: [],	// copied below
		use_terms: this.learning_progression.use_terms,
		use_unit_numbers: this.learning_progression.use_unit_numbers,
		use_unit_intervals: this.learning_progression.use_unit_intervals,
		unit_collection_title: this.learning_progression.unit_collection_title,
		sparkl_origin_override: this.learning_progression.sparkl_origin_override,
		resource_collection_ids: this.learning_progression.resource_collection_ids.concat([]),

		additional_my_options_showing: false,

		changing_subject_case_title: false,

		import_warning_shown: false,
		import_terms_data: '',

		unit_deletion_confirmed: false,

		initial_values: '',
		initial_subject: '',

		drag_options: {
		    animation: 200,
		    handle: '.moveHandle',
		},

		is_new_lp: false,
		new_categories: [],
	}},
	computed: {
		...mapState(['user_info', 'frameworks_loaded', 'framework_records', 'all_courses_loaded', 'all_courses']),
		...mapGetters([]),
		// note that you can't change collection_type in the editor (but you can do this in the admin tool)
		collection_type() { return this.learning_progression.collection_type },
		is_repo_or_pd() { return this.collection_type == 'repo' || this.collection_type == 'pd' },
		is_repo() { return this.collection_type == 'repo'},
		dialog_title() {
			let s = this.is_new_lp ? 'New ' : 'Edit '
			if (this.collection_type == 'course') return s + 'Course Content Collection'
			if (this.collection_type == 'repo') return s + 'Resource Repository'
			if (this.collection_type == 'pd') return s + 'PD Content Collection'
			return s + 'Content Collection'	// 'my'/default
		},
		grades() {
			let arr = []
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		grades_low() {
			return [{value:'', text:'GRADE RANGE START'}].concat(this.grades)
		},
		grades_high() {
			return [{value:'', text:'GRADE RANGE END'}].concat(this.grades)
		},
		subjects() {
			let arr = []
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			arr.push({value: '-', text: 'No subject chosen'})
			return arr
		},
		subject_frameworks() {
			let arr = []
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: this.$store.state.subjects[subject].framework_identifier, text: subject})
				// console.log(subject)
				// add alt_frameworks if there
				if (this.$store.state.subjects[subject].alt_frameworks) {
					for (let alt_framework_title in this.$store.state.subjects[subject].alt_frameworks) {
						arr.push({ value: this.$store.state.subjects[subject].alt_frameworks[alt_framework_title], text: alt_framework_title})
					}
				}
			}
			for (const [guid, name] of Object.entries(this.$store.state.site_config.case_framework_options_for_collections)) {
				arr.push({ value: guid, text: name})
			}
			arr.push({value: 'none', text: 'None (don’t show course standards for this framework)'})
			return arr
		},
		subject_case_title() {
			if (this.subject_case_identifier == 'none') return 'No course standards'

			let f = this.framework_records.find(x=>x.lsdoc_identifier == this.subject_case_identifier)
			if (f) return f.json.CFDocument.title
			else return this.subject_case_identifier
		},
		course_case_title() {
			if (!this.course_case_identifier || this.subject_case_identifier == 'none') return ''

			let f = this.framework_records.find(x=>x.lsdoc_identifier == this.subject_case_identifier)
			if (f) {
				if (!f.framework_json_loaded) {
					this.$store.dispatch('get_lsdoc', f.lsdoc_identifier)
					return 'Loading…'
				} else if (f.json.CFItems) {
					let i = f.json.CFItems.find(x=>x.identifier == this.course_case_identifier)
					if (i) {
						return U.generate_cfassociation_node_uri_title(i, true)
					}
				}
			}
			// if we get to here, return the identifier
			return this.course_case_identifier
		},
		// color_select() { return this.$store.state.color_select },
		repo_dropdown() {
			// get list of all categories from courses
			let repo_cats = []
			for (let lp of this.all_courses) {
				if (lp.collection_type == 'repo' || lp.collection_type == 'pd') {
					if (lp.repo_category && !repo_cats.includes(lp.repo_category)) repo_cats.push(lp.repo_category)
				}
			}
			// sort the cats
			repo_cats.sort(U.natural_sort)

			// build array for drop-down, with value (including the sequence number) and text (not including the sequence number)
			let arr = []
			for (let rc of repo_cats) {
				// PW: seems like rc.split('_')[1] would work just as well...
				arr.push({value: rc, text: rc.split('_').slice(1).join('_')})
			}

			for (let nc of this.new_categories) {
				arr.push({value: nc, text: nc.split('_').slice(1).join('_')})
			}

			arr.push({value: 'x', text: '-- CREATE A NEW CATEGORY --'})

			return arr
		}
	},
	created() {
	},
	mounted() {
		// the options that are optional for 'my' collections should always show for other types
		if (this.collection_type != 'my') this.additional_my_options_showing = true

		this.is_new_lp = empty(this.learning_progression.title)

		this.initial_values = JSON.stringify(this.learning_progression.copy_for_save())

		// create a copy of the lp to get a copy of of terms, units
		let lp_copy = new Learning_Progression(this.learning_progression)
		this.terms = lp_copy.terms
		this.units = lp_copy.units

		// get frameworks list if not already loaded -- but we don't have to wait for it to load
		if (!this.frameworks_loaded) this.$store.dispatch('get_lsdoc_list')

		// get all courses if not already loaded -- but we don't have to wait for it to load
		if (!this.all_courses_loaded) this.$store.dispatch('get_all_courses')

		// this used by the standards chooser hide_fn
		vapp.course_editor = this
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				zIndex: 1000,
				// initOnClick: true,
				toolbarInline: (inline === true),
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
			})
		},

		choose_course_case_identifier() {
			if ((empty(this.subject) || this.subject == '-') && empty(this.subject_case_identifier)) {
				this.$alert('You must choose a Subject, or choose a Standards Framework, in order to choose a course CASE identifier.')
				return
			}
			
			let data = { framework_identifier: this.subject_case_identifier }

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($(vapp.course_editor?.$el).is(':visible') == false) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if for some reason the user chose a different framework, save it as the subject_case_identifier
						if (aligned_item.framework_identifier != this.subject_case_identifier) {
							this.subject_case_identifier = aligned_item.framework_identifier
						}
						this.course_case_identifier = aligned_item.cfitem.identifier

						// hide the chooser, because we only choose one item here
						vapp.$refs.satchel.execute('hide')

						// save immediately, unless this is a new lesson -- currently not doing this because when you save it always closes the editor
						// if (!empty(this.learning_progression.course_code)) this.save_edits()
					})
				})
			})
		},
		repo_category_changed() {
			if (this.repo_category === 'x') {
				this.$prompt({
					title: 'Create a New Category',
					text: 'Enter category name:',
					initialValue: '',
					disableForEmptyValue: true,
					acceptText: 'Create Category',
					acceptIconAfter: 'fas fa-arrow-right',
				}).then(cat_name => {
					// MC: it doesn't matter what number the number is before the category name, as long as it is greater than the current amount of items in the repo_categories since it will go to the very end;
					//     when the user reorders the categories, the category name will fix itself
					cat_name = '999_' + cat_name
					this.new_categories.push(cat_name)
					this.repo_category = cat_name
				}).catch(n=>{this.repo_category = ""}).finally(f=>{})
			}
		},

		title_changed() {
			// not doing the below as of 12/29/2023, because it fouls some things up
			return

			if (empty(this.title)) return

			// try to extract grade and subject from title if not already set
			if (empty(this.grade_low)) {
				if (this.title.search(/KK/i) > -1) {
					this.grade_low = 'K'
					this.grade_high = 'K'
				} else if (this.title.search(/(\d+)/i) > -1) {
					let grade = RegExp.$1 * 1
					if (grade >= 1 && grade <= 12) {
						this.grade_low = grade+''
						this.grade_high = grade+''
					}
				}
			}

			if (empty(this.subject)) {
				for (let subject in this.$store.state.subjects) {
					if (this.title.search(new RegExp(subject)) > -1) {
						this.subject = subject
						break
					}
				}
			}
		},

		grade_low_changed() {
			if (empty(this.grade_high) || U.grade_value(this.grade_high) < U.grade_value(this.grade_low)) {
				this.grade_high = this.grade_low
			}
		},

		grade_high_changed() {
			if (empty(this.grade_low) || U.grade_value(this.grade_low) > U.grade_value(this.grade_high)) {
				this.grade_low = this.grade_high
			}
		},

		subject_changed() {
			// if subject is chosen/changed...
			if (!empty(this.$store.state.subjects[this.subject])) {
				// if subject_case_identifier wasn't originally entered or is currently empty, or if it equals the value for the original subject,
				if (empty(this.subject_case_identifier) || empty(this.learning_progression.subject) || this.learning_progression.subject_case_identifier == this.$store.state.subjects[this.learning_progression.subject].framework_identifier) {
					// enter the value for the new subject
					this.subject_case_identifier = this.$store.state.subjects[this.subject].framework_identifier
				}
			}
		},

		create_new_term() {
			this.terms.push({
				title: '',
				duration: ''
			})
		},

		delete_term(term) {
			// if we get to here just delete the term after confirming
			this.$confirm({
				text: 'Are you sure you want to delete this term?',
				acceptText: 'Delete Term',
			}).then(y => {
				this.terms.splice(this.terms.findIndex(x=>x==term), 1)
			}).catch(n=>{}).finally(f=>{});
		},

		term_title_changed(term) {
			// if user enters just a number, change to "Quarter #"
			if (!empty(term.title) && !isNaN(term.title*1)) {
				term.title = 'Quarter ' + term.title
			}
		},

		create_new_unit_from_standard(start_identifier) {
			let data = { framework_identifier: '', item_identifier: '' }
			if (!empty(this.subject_case_identifier)) data.framework_identifier = this.subject_case_identifier
			if (typeof(start_identifier) == 'string') data.item_identifier = start_identifier
			else if (!empty(this.course_case_identifier)) data.item_identifier = this.course_case_identifier

			let show_data = { 
				// set embed_hide_callback_fn to toggle aligning_to_standards off when the user closes the chooser
				embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },
				// set hide_fn to hide the standards chooser if/when the editor is no longer visible
				hide_fn: ()=>{ return ($(vapp.course_editor?.$el).is(':visible') == false) },
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((o) => {
						// prompt to ask user if they want to prefix the unit title with anything, and if they want to set the unit standards
						let note = o.cfitem.humanCodingScheme ? `<br><br><i style="font-size:14px">Note: the human readable code (${o.cfitem.humanCodingScheme}) will be used as the “Unit Number”.</i>` : ''
						let initial_title = o.cfitem.fullStatement
						if (!empty(this.create_new_unit_from_standard_prefix)) initial_title = this.create_new_unit_from_standard_prefix + initial_title
						this.$prompt({
							title: 'Title',
							text: `Enter the unit title:${note}`,
							secondaryCheckbox: 'Align the new unit to this standard and its children',
							secondaryCheckboxInitiallyOn: true,
							initialValue: initial_title,
							disableForEmptyValue: true,
							acceptText: 'Create Unit',
							hideCancel: false,	// set to true to hide the cancel button
						}).then(arr => {
							let title = $.trim(arr[0])
							let re = new RegExp('^(.+)' + o.cfitem.fullStatement)
							if (title.search(re) > -1) this.create_new_unit_from_standard_prefix = RegExp.$1
							else this.create_new_unit_from_standard_prefix = ''

							let u = new LP_Unit({title: title})
							if (o.cfitem.humanCodingScheme) u.display_number = o.cfitem.humanCodingScheme
							// if user clicked box to save associated standards, save them too
							if (arr[1]) {
								// this only works if they choose an item from the collection's framework -- we have the full json loaded for this framework
								let framework_record = this.framework_records.find(x=>x.lsdoc_identifier == o.framework_identifier)
								if (!framework_record) {
									this.$alert('This only works if you choose a standard from the collection’s subject framework.')
									return
								}
								u.standards = [new CASE_Item(o.cfitem, o.framework_identifier)]
								this.add_child_standards(u, framework_record, o.cfitem.identifier)
							}
							console.warn('creating unit from standard', u)
							this.units.push(u)

							// re-call this fn so the author can add another unit if they want
							this.create_new_unit_from_standard(o.cfitem.identifier)

						}).catch(n=>{console.log(n)}).finally(f=>{})
						// vapp.$refs.satchel.execute('hide')
					})
				})
			}).catch(()=>{ vapp.$refs.satchel.execute('hide') })	// this will execute when the standards are hidden
		},

		// recursive fn to store descendent standards
		add_child_standards(unit, framework_record, parent_identifier) {
			// look for all children of the parent -- isChildOf assocs where the destination is the parent_identifier
			let assocs = framework_record.json.CFAssociations.filter(o => o.associationType == 'isChildOf' && o.destinationNodeURI.identifier == parent_identifier)
			for (let assoc of assocs) {
				let item = framework_record.json.CFItems.find(x=>x.identifier == assoc.originNodeURI.identifier)
				if (empty(item)) {
					console.error(`couldn’t find child item ${assoc.originNodeURI.identifier}`)
					continue	// shouldn't happen
				}
				// add the child item
				unit.standards.push(new CASE_Item({
					framework_identifier: framework_record.lsdoc_identifier,
					identifier: item.identifier,
					fullStatement: item.fullStatement,
					humanCodingScheme: item.humanCodingScheme ?? '',
					educationLevel: item.educationLevel ?? [],
					lastChangeDateTime: item.lastChangeDateTime,
					childOf: parent_identifier,
					sequenceNumber: assoc.sequenceNumber ?? 0,
				}))

				// recurse to add grandchildren
				this.add_child_standards(unit, framework_record, item.identifier)
			}
		},

		create_new_unit() {
			let u = new LP_Unit()

			// if this is a course collection and we have site_config.default_folders_for_course_units, create default folders for the unit
			if (this.collection_type == 'course' && !empty(this.$store.state.site_config.default_folders_for_course_units)) {
				let create_folders = (o, parent_folder_id) => {
					for (let key in o) {
						let val = o[key]
						let f = u.create_resource_folder({title:val.title, parent_folder_id:parent_folder_id, color:val.color, cross_unit_key:val.cross_unit_key})
						if (val.children) {
							create_folders(val.children, f.folder_id)
						}
					}
				}
				create_folders(this.$store.state.site_config.default_folders_for_course_units, 'top')
			}

			// remove duration value so label shows in input
			u.duration = ''
			this.units.push(u)
		},

		delete_unit(unit) {
			if (this.unit_deletion_confirmed) {
				this.units.splice(this.units.findIndex(x=>x==unit), 1)

			} else {
				this.$confirm({
					text: 'Are you sure you want to delete this unit? (Note: you will not be asked to confirm subsequent unit deletions.)',
					acceptText: 'Delete Unit',
				}).then(y => {
					this.units.splice(this.units.findIndex(x=>x==unit), 1)
					// don't ask again
					this.unit_deletion_confirmed = true
				}).catch(n=>{}).finally(f=>{});
			}
		},

		// Drag is implemented
		// move_unit(unit, direction) {
		// 	let i = this.units.findIndex(x=>x==unit)

		// 	if (direction == 'up') {
		// 		if (i > 0) {
		// 			this.units.splice(i, 1)
		// 			this.units.splice(i-1, 0, unit)
		// 		}
		// 	} else {
		// 		if (i < this.units.length - 1) {
		// 			this.units.splice(i, 1)
		// 			this.units.splice(i+1, 0, unit)
		// 		}
		// 	}
		// },

		unit_number_changed(unit) {
			// if user enters just a number, change to "Unit #"
			if (!empty(unit.display_number) && !isNaN(unit.display_number*1)) {
				console.log('here: ' + unit.display_number)
				unit.display_number = 'Unit ' + unit.display_number
			}
		},

		clear_resource_collection(index) {
			// clear the last resource_collection_ids value
			this.resource_collection_ids.splice(index, 1)
		},

		save_edits(course_code_confirmed) {
			// trim values
			this.title = $.trim(this.title)
			this.course_code = $.trim(this.course_code)
			this.description = window.trim_froala_text(this.description)

			if (this.units.length > 1) {
				for (let i = 0; i < this.units.length; i++) {
					if (empty(this.units[i].title)) {
						this.$alert('Each unit must must have a title.')
						return false
					}
				}
			}

			if (empty(this.title)) {
				this.$alert('The content collection must have a title.')
				return false
			}

			if (this.collection_type == 'course' && empty(this.course_code)) {
				this.$alert('You must enter a course code.')
				return false
			}

			// 01.23 - 1130.123456
			// if this is a new lp, or the course_code has changed, check to make sure the course_code doesn't already exist
			let is_new_lp = false
			if (empty(this.learning_progression.course_code) || this.learning_progression.course_code != this.course_code) {	// note that this is checking the course_code sent in via props, not what the user entered
				is_new_lp = true

				let e = this.$store.state.all_courses.find(o=>o.course_code==this.course_code)
				if (e) {
					this.$alert(sr('A Course for this course code ($1) already exists (the Course is titled “$2”).', e.course_code, e.title))
					return false
				}
			}

			// if we made it through the above check, and the course code existed and has changed, ask if they're sure they want to do this
			if (!empty(this.learning_progression.course_code) && this.course_code != this.learning_progression.course_code && course_code_confirmed !== true) {
				this.$confirm({
				    title: 'Are you sure?',
				    text: 'Are you sure you want to change the Course Code? Only do this if you know what you’re doing!',
				    acceptText: 'I’m Sure',
				}).then(y => {
					this.save_edits(true)
				}).catch(n=>{console.log(n)}).finally(f=>{})

				return false
			}

			if (this.collection_type == 'course' && (empty(this.grade_low) || empty(this.grade_high))) {
				this.$alert('You must set the start and end of the grade range (the minimum and maximum grade levels can be equal).')
				return false
			}

			if (this.collection_type == 'course' && empty(this.subject)) {
				this.$alert('You must set the subject area.')
				return false
			}

			// if subscription_only is true and we don't have a subscription_code, call service to get a code
			if (this.subscription_only && empty(this.subscription_code)) {
				// call service here...
				this.$store.dispatch('generate_subscription_code', this.learning_progression.lp_id).then(res => {
					// in `then`, set subscription_code and re-call save_edits
					this.subscription_code = res.subscription_code
					this.updated_at = res.updated_at
					this.save_edits()
				})
				return
			}

			// validate term/unit week values
			// for (let term of this.terms) {
			// 	if (isNaN(term.duration*1)) {
			// 		this.$alert('You must enter a numeric value for “Weeks” for all terms. (Note that “0” <i>is</i> a valid entry.)')
			// 		return false
			// 	}
			// }
			// for (let unit of this.units) {
			// 	if (isNaN(unit.duration*1)) {
			// 		this.$alert('You must enter a numeric value for “Weeks” for all units. (Note that “0” <i>is</i> a valid entry.)')
			// 		return false
			// 	}
			// }

			this.$store.commit('set', [this.learning_progression, 'title', this.title])
			this.$store.commit('set', [this.learning_progression, 'lp_layout', this.lp_layout])
			this.$store.commit('set', [this.learning_progression, 'updated_at', this.updated_at])
			this.$store.commit('set', [this.learning_progression, 'course_code', this.course_code])
			this.$store.commit('set', [this.learning_progression, 'description', this.description])
			this.$store.commit('set', [this.learning_progression, 'subject_case_identifier', this.subject_case_identifier])
			this.$store.commit('set', [this.learning_progression, 'course_case_identifier', this.course_case_identifier])
			this.$store.commit('set', [this.learning_progression, 'grade_low', this.grade_low])
			this.$store.commit('set', [this.learning_progression, 'grade_high', this.grade_high])
			this.$store.commit('set', [this.learning_progression, 'subject', this.subject]) // probably don't need this since it is in the watcher
			this.$store.commit('set', [this.learning_progression, 'color', this.color])
			this.$store.commit('set', [this.learning_progression, 'terms', this.terms])
			this.$store.commit('set', [this.learning_progression, 'units', this.units])
			this.$store.commit('set', [this.learning_progression, 'use_terms', this.use_terms])
			this.$store.commit('set', [this.learning_progression, 'use_unit_numbers', this.use_unit_numbers])
			this.$store.commit('set', [this.learning_progression, 'use_unit_intervals', this.use_unit_intervals])
			this.$store.commit('set', [this.learning_progression, 'unit_collection_title', this.unit_collection_title])
			this.$store.commit('set', [this.learning_progression, 'sparkl_origin_override', this.sparkl_origin_override])
			this.$store.commit('set', [this.learning_progression, 'resource_collection_ids', this.resource_collection_ids])
			this.$store.commit('set', [this.learning_progression, 'active', this.active ? 'yes' : 'no'])
			this.$store.commit('set', [this.learning_progression, 'subscription_code', this.subscription_code])
			this.$store.commit('set', [this.learning_progression, 'subscription_only', this.subscription_only])
			if (this.is_repo_or_pd) this.$store.commit('set', [this.learning_progression, 'repo_category', this.repo_category])

			if (JSON.stringify(this.learning_progression.copy_for_save()) != this.initial_values) {
				this.$store.dispatch('save_learning_progression', this.learning_progression).then(()=>{
					// note that store will update anything needed in this.learning_progression
					// if course code changed, have to refresh to the new collection
					if (course_code_confirmed === true) {
						document.location.pathname = this.learning_progression.vue_route()
					} else {
						// if is_new_lp, add to all courses
						if (is_new_lp) {
							this.$store.commit('add_to_array', [this.all_courses, this.learning_progression])
						}
						this.cancel_editor()
						this.$router.push(this.learning_progression.vue_route())
					}

					// set collection.full_description_height to -1 so that the description resizer re-runs
					this.$store.commit('set', [this.learning_progression, 'full_description_height', -1])
				})
			} else {
				console.log('nothing changed, so not saving')
				this.cancel_editor()
			}
			// return true to signal that the save was able to proceed
			return true
		},


		cancel_editor(edit_action) {
			this.$nextTick(()=>this.$emit('editor_cancel'))
		},

		import_terms_btn_clicked() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Open the Curriculum Map document in Microsoft Word, choose “Save As” from the File menu, then save the document in “Web Page, filtered (.htm)” format. Then use the file upload interface below to find and process the .htm file you just saved:</p>',
				promptType: 'file',
				acceptText: 'Process File',
				cancelText: 'Cancel'
			}).then(file => {
				if (file.type != 'text/html') {
					this.$alert('You must save the file in “Web Page, filtered (.htm)” format, which will result in a file that ends in “.htm” or “.html” The file you attempted to process doesn’t have the right extension.').then(()=>this.import_terms_btn_clicked())
					return
				}

				let reader = new FileReader()
				reader.onload = e => {
					this.process_lp_html(e.target.result)
				}
				reader.readAsText(file)

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_lp_find_next_unit(units, param) {
			// find the next unit whose param is empty or 0
			for (let unit of units) {
				if (empty(unit[param]) || unit[param] === 0) return unit
			}
			return null
		},

		process_lp_html(html) {
			let report = ''
			let has_errors = false

			// get the html and find the "WordSection1" node
			let nodes = $.parseHTML(html)
			let jq = $(nodes)
			// for (let node of nodes) {
			// 	jq = $(node)
			// 	if (jq.hasClass('WordSection1')) break
			// }

			if (empty(jq)) { this.show_lp_import_error(1, report); return; }

			let terms = []
			let units = []

			function add_to_report(s, level) {
				console.log(s)

				// tags
				s = s.replace(/</g, '&lt;')

				// if line starts with **, mark as possible error
				if (s.indexOf('**') == 0) {
					s = s.replace(/^(\*\*.*)/, '<b style="color:red">$1</b>')
					has_errors = true
				}

				// indentation
				for (let li = 0; li < level; ++li) {
					s = '  ' + s
				}

				report += s + '<br>'
			}

			// document may have multiple tables...
			let tables = jq.find('table')
			add_to_report(sr('Found $1 tables',tables.length), 0)
			for (let ti = 0; ti < tables.length; ++ti) {
				add_to_report('Processing table #' + (ti+1), 0)
				// look for rows in the table
				let rows = $(tables[ti]).find('tr')
				if (empty(rows) || rows.length == 0) {
					add_to_report('*** No rows in table #' + ti, 1)
					continue
				}

				// go through each row of the table...
				let last_row = ''
				let terms_repeat = false
				for (let r = 0; r < rows.length; ++r) {
					let row = $(rows[r])
					let rt = $.trim(row.text()).toLowerCase()

					// if we find a row that starts with 'quarter/semester/term', that row should specify the terms
					if (rt.search(/(quarter|semester|term)/) == 0 && last_row == '') {
						add_to_report('')
						add_to_report('found terms...', 1)
						last_row = 'terms'

						for (let td of row.find('td')) {
							let $td = $(td)
							let term_title = $.trim($td.text().replace(/\s+/g, ' '))

							if (empty(term_title)) {
								add_to_report('*** Empty td in term row', 2)
							} else {
								// if the first term_title we find matches the first term_title we have in terms,
								// assume that this is a "repeat table" that just includes additional text
								if (terms.length > 0 && term_title == terms[0].title) {
									terms_repeat = true
									add_to_report('*** Assuming this table includes repeated terms from previous table')
									break
								}

								terms.push({
									title: term_title,
									duration: '9',	// assume 9 weeks
								})
								add_to_report('term: ' + terms[terms.length-1].title, 2)
							}
						}

						continue	// done with the row
					}

					// if we find a row that starts with 'unit' after the 'terms' row (or before we process any row -- we may have "term-less units"),
					// this row should specify the unit numbers, which we store as the "title"
					if (rt.search(/(unit)/) == 0 && (last_row == 'terms' || last_row == '')) {
						add_to_report('')
						add_to_report('found units...', 1)
						last_row = 'units'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							let title = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(title)) {
								add_to_report('*** Empty td in unit title row', 2)
							} else {
								let unit = new LP_Unit({title: title})
								units.push(unit)
								add_to_report('unit: ' + unit.display_number, 2)
							}
						}

						continue	// done with the row
					}

					// if we find a row that includes the word 'week' right after the 'units' row, this row should specify the unit durations, which we store as "duration"
					if (rt.search(/(week)/) > -1 && last_row == 'units') {
						add_to_report('')
						add_to_report('found durations...', 1)
						last_row = 'durations'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							let duration_text = $.trim($(td).text().replace(/\s+/g, ' ')).toLowerCase()
							if (empty(duration_text)) {
								add_to_report('*** Empty td in unit duration row', 2)
							} else {
								let duration = duration_text.replace(/([\d.]+) week(s)?/i, '$1')*1
								if (isNaN(duration)) {
									add_to_report('*** Bad unit duration found: ' + $(td).text(), 2)
									duration = 1
								}

								// find the next unit to fill in
								let unit = this.process_lp_find_next_unit(units, 'duration')
								if (empty(unit)) {
									add_to_report('*** More unit durations found than units -- possible error')
									continue
								}

								unit.duration = duration
								add_to_report(sr('unit “$1” duration: $2', unit.display_number, unit.duration), 2)
							}
						}

						continue	// done with the row
					}

					// the row right after durations should specify the unit “titles”, which we store as 'description'
					if (last_row == 'durations') {
						add_to_report('')
						add_to_report('found descriptions...', 1)
						last_row = 'descriptions'

						if (terms_repeat == true) {
							add_to_report('*** Assuming this table includes repeated units from previous table')
							continue
						}

						// go through each unit's td
						for (let td of row.find('td')) {
							// TODO: do we need to worry about preserving html here? hopefully not
							let description = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(description)) {
								add_to_report('*** Empty td in unit description row', 2)
							} else {
								// find the next unit to fill in
								let unit = this.process_lp_find_next_unit(units, 'description')
								if (empty(unit)) {
									add_to_report('*** More unit titles found than units -- possible error')
									continue
								}
								unit.title = description
								add_to_report(sr('unit “$1” description: $2', unit.display_number, unit.title), 2)
							}
						}

						continue	// done with the row
					}

					// the row right after descriptions should be the unit text
					if (last_row == 'descriptions') {
						add_to_report('')
						add_to_report('found unit text...', 1)
						last_row = 'text'

						// go through each unit's td
						let ui = -1
						for (let td of row.find('td')) {
							++ui
							let text_text = $.trim($(td).text().replace(/\s+/g, ' '))
							if (empty(text_text)) {
								add_to_report('*** Empty td in unit text row', 2)
							} else {
								// find the next unit to fill in
								let unit
								if (terms_repeat) {
									unit = units[ui]
								} else {
									unit = this.process_lp_find_next_unit(units, 'text')
								}

								if (empty(unit)) {
									add_to_report('*** More unit text columns found than units -- possible error')
									continue
								}

								// clean contents...
								$(td).find('*').each(function(i, el) {
									let tag = $(el).prop('tagName')
									let html = $(el).html()
									html = html.replace(/\&nbsp;/g, ' ')
									html = html.replace(/style=".*?"/g, '')
									html = html.replace(/<(\/)?span\b.*?>/g, '')
									$(el).replaceWith(sr('<$1>$2</$3>', tag, $.trim(html), tag))
								})
								let text = $(td).html().replace(/<p>(\s)*<\/p>/g, '')	// remove blank lines
								text = text.replace(/\s+/g, ' ')	// consolidate spaces
								text = $.trim(text)

								unit.text += text
								add_to_report(sr('unit “$1” text: $2…', unit.display_number, unit.text.substr(0, 20)), 2)
							}
						}

						break	// once we do this row, we're done processing
					}
				}
				add_to_report('')
			}

			// clear units that have no title, duration, or description
			for (let i = 0; i < units.length; ++i) {
				if (units[i].display_number == '' && units[i].duration == 0 && units[i].title == '') {
					units.splice(i, 1)
					--i
				}
			}

			// if we have existing unit ids, reuse them
			for (let i = 0; i < this.learning_progression.units.length; ++i) {
				if (this.learning_progression.units[i].lp_unit_id != 0) {
					units[i].lp_unit_id = this.learning_progression.units[i].lp_unit_id
				}
			}

			add_to_report('DONE!')
			console.log(terms, units)

			if (terms.length > 0 || units.length > 0) {
				this.terms = terms
				this.units = units
				let msg = sr('Imported $1 terms and $2 units. After closing this dialog, click “Save” at the bottom of the page to save the imported terms and units, or “Cancel” to cancel the import and revert to any previously-saved terms and units.', terms.length, units.length)
				if (has_errors) {
					msg += '<br><br><b style="color:red">Note:</b> Possible errors were found in the import process; see Import Report.'
				}
				this.$confirm({
				    title: 'File Processed',
				    text: msg,
				    acceptText: 'Done',
				    cancelText: 'View Import Report',
					dialogMaxWidth: 600
				}).then(y => {
				}).catch(n=>{
					report = sr('<pre>$1</pre>', report)
					this.$alert({title: 'Import Processing Report', text: report, dialogMaxWidth:800})
				}).finally(f=>{})

				this.import_terms_showing = false
			} else {
				this.$alert('No terms or units imported. Sorry!!')
			}
		},

		show_lp_import_error(errnum, report) {
			report = sr('<pre>$1</pre>', report)
			this.$alert({title: sr('Error parsing file ($1)', errnum), text: report, dialogMaxWidth:800})
		},

		import_cc_btn_clicked() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Extract the Thin Common Cartridge to produce a single XML file. Then use the file upload interface below to find and process the XML file:</p>',
				promptType: 'file',
				acceptText: 'Process XML File',
				cancelText: 'Cancel'
			}).then(file => {
				console.log(file)
				if (file.type != 'text/xml') {
					this.$alert('You must upload an XML file (.xml). The file you attempted to process doesn’t have the right extension.').then(()=>this.import_cc_btn_clicked())
					return
				}

				let reader = new FileReader()
				reader.onload = e => {
					this.process_cc_xml(e.target.result)
				}
				reader.readAsText(file)

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		process_cc_xml(xml) {
			let $xml = $($.parseXML(xml))
			// window.$xml = $xml

			function get_text_from_child_tags(node, arr, obj, key) {
				let child_node = node
				// search down for the tag_names given in arr
				for (let tag_name of arr) {
					child_node = child_node.getElementsByTagName(tag_name)
					if (child_node.length > 0) {
						child_node = child_node[0]
					} else {
						break
					}
				}
				// if we don't find the child_node, do nothing
				if (empty(child_node) || empty(child_node.textContent)) {
					return ''
				}

				// if obj is 'text', return the trimmed content
				if (obj == 'text') return $.trim(child_node.textContent)

				// if obj is 'node', return the node
				if (obj == 'node') return child_node

				// else we should have received obj/key; set key to the trimmed content
				if (!empty(obj)) {
					obj[key] = $.trim(child_node.textContent)
				}
			}

			function get_toc_item($xml, resources, item_node) {
				if (empty(item_node.children)) return null

				let o = {}
				for (let child_node of item_node.children) {
					// extract title
					if (child_node.tagName == 'title') o.t = $.trim(child_node.textContent)

					// get child items
					if (child_node.tagName == 'item') {
						let child = get_toc_item($xml, resources, child_node)
						if (!empty(child)) {
							if (empty(o.c)) o.c = []
							o.c.push(child)
						}
					}

					// if the item has metadata,...
					if (child_node.tagName == 'metadata') {
						// then if we have an identifierref, and we find a resource matching it, get/process the resource
						let identifierref = item_node.getAttribute('identifierref')
						if (!empty(identifierref)) {
							let $resource = $xml.find(sr('[identifier="$1"]', identifierref))
							if ($resource.length == 1) {
								// store the resource identifier in the object
								o.r = identifierref

								// if we already have this resource in the resources array, don't duplicate it
								if (empty(resources.find(x=>x.resource_id == identifierref))) {
									// note that we use the identifierref from the CC file as the resource_id; if the tcc file is re-uploaded, old resources will be overwritten (which is what we want)
									let resource_json = {
										// we store the title in 'description', and the description in 'long_description'
										description: o.t,
										resource_id: identifierref,
										type: 'lti',
										lti_params: {},
										shareable: true,
									}

									// look for a long_description, which comes out of the child_node itself
									get_text_from_child_tags(child_node, ['lomr:lom', 'lomr:general', 'lomr:description'], resource_json, 'long_description')

									// TODO: do some error checking here!

									// get other values from the $resource...
									// lti endpoint and  from the $resource
									get_text_from_child_tags($resource[0], ['lticc:cartridge_basiclti_link', 'blti:secure_launch_url'], resource_json.lti_params, 'endpoint')

									// role (learner or instructor); need to search through nodes for this...
									let ed_nodes_parent = get_text_from_child_tags($resource[0], ['metadata', 'lomr:lom', 'lomr:educational'], 'node')
									if (!empty(ed_nodes_parent) && ed_nodes_parent.children.length > 0) {
										let ed_nodes = ed_nodes_parent.getElementsByTagName('lomr:intendedEndUserRole')
										for (let ed_node of ed_nodes) {
											if (get_text_from_child_tags(ed_node, ['lomr:source'], 'text') == 'IMSGLC_CC_Rolesv1p2') {
												// look for role in metadata / lomr:lom / lomr:educational / lomr:intendedenduserrole
												// where lomr:source is 'IMSGLC_CC_Rolesv1p2';
												// if that node's lomr:value is 'Instructor', it's teacher-facing; otherwise assume student-facing
												let role = get_text_from_child_tags(ed_node, ['lomr:value'], 'text')
												resource_json.teacher_facing = ((''+role).toLowerCase() == 'instructor')

												// if teacher-facing, store "i:1" ('i' meaning 'instructor') in o (i.e. in the resource_collection_json)
												if (resource_json.teacher_facing) o.i = 1
											}
										}
									}

									// lti custom tags: there may be multiple of these, so get the node, and if it exists and has children...
									let custom_node = get_text_from_child_tags($resource[0], ['lticc:cartridge_basiclti_link', 'blti:custom'], 'node')
									if (!empty(custom_node) && custom_node.children.length > 0) {
										for (let custom_prop_node of custom_node.children) {
											// check that the child is called 'lticm:property', and extract the name attribute and text
											if (custom_prop_node.tagName == 'lticm:property') {
												let prop_name = 'custom_' + custom_prop_node.getAttribute('name')
												resource_json.lti_params[prop_name] = $.trim(custom_prop_node.textContent)
											}
										}
									}

									// CASE standards: there may be multiple of these, so get the node, and if it exists and has children...
									let standard_nodes_parent = get_text_from_child_tags($resource[0], ['metadata', 'csm:curriculumStandardsMetadataSet', 'csm:curriculumStandardsMetadata', 'csm:setOfGUIDs'], 'node')
									if (!empty(standard_nodes_parent) && standard_nodes_parent.children.length > 0) {
										// each child should have structure `<csm:labelledGUID><csm:label>xxx</csm:label><csm:GUID>yyy</csm:GUID></csm:labelledGUID>`
										for (let standard_node of standard_nodes_parent.children) {
											// check that the child is called 'csm:labelledGUID', and extract the text of the csm:GUID tag
											if (standard_node.tagName == 'csm:labelledGUID') {
												// push case_identifier into array in resource_json
												let case_identifier = get_text_from_child_tags(standard_node, ['csm:GUID'], 'text')
												if (empty(resource_json.case_identifiers)) resource_json.case_identifiers = []
												resource_json.case_identifiers.push(case_identifier)
											}
										}
									}

									// use the Resource class/copy_for_save to make sure we get the data in the right format
									resources.push(new Resource(resource_json).copy_for_save())
								}
							}
						}
					}
					// note that the item will likely have either child items *or* a resource; but handle the case where we have both
				}

				// if we didn't get a title or children, return null (not sure if this would happen...)
				if (empty(o.t) && empty(o.c)) return null

				// if we did get children, store the identifier of the item as 'f'; we use these to mark "folders" of items to include for certain units
				if (!empty(o.c)) {
					o.f = item_node.getAttribute('identifier')
				}

				return o
			}

			// try to find the root toc item in the cc $xml
			let toc_item = $xml.find('organization[structure="rooted-hierarchy"] item[identifier=root]')
			if (toc_item.length != 1) {
				this.$alert(sr('The Thin Common Cartridge file could not be processed (error 483:)', toc_item.length))
				return
			}

			// look for an identifier in the manifest, to use as the resource_id of the "collection resource"; if not found use a uuid
			let resource_collection_resource_id = $xml.find('manifest').attr('identifier')
			if (empty(resource_collection_resource_id)) resource_collection_resource_id = U.new_uuid()

			// extract toc tree and resources. we have to delay starting the process a bit to allow the loading indicator/message to appear
			U.loading_start('Processing Common Cartridge file; this may take up to a couple of minutes...')
			setTimeout(()=>{
				let resources = []
				let resource_collection_json = get_toc_item($xml, resources, toc_item[0])
				console.log(resource_collection_json)
				console.log(resources)

				// U.download_json_file(resource_collection_json, 'TCC-TREE-' + this.learning_progression.title)
				// U.download_json_file(resources, 'TCC-RESOURCES-' + this.learning_progression.title)

				U.loading_stop()

				// tell the user what happened...
				let msg = sr('Found $1 resources (see browser debugger to inspect data). To save the resources in the system and link them to this Curriculum Map, enter a title for the resource collection (e.g. “Science 3rd Grade HMH Resources”) and click “Save”.', resources.length)
				this.$prompt({
				    title: 'Processing Successful',
				    text: msg,
				    acceptText: 'Save',
				}).then(resource_collection_title => {
					if (empty(resource_collection_title)) return

					// finally save the resources
					let payload = {
						resource_collection_resource_id: resource_collection_resource_id,
						resource_collection_title: resource_collection_title,
						resource_collection_json: resource_collection_json,
						resources: resources
					}
					this.$store.dispatch('save_resource_collection', payload).then((resource_collection_resource_id)=>{
						// when save is complete...
						// push the resource collection's resource_id onto resource_collection_ids, unless it already exists (in which case we just re-processed the TCC)
						if (!this.resource_collection_ids.find(o=>o==resource_collection_resource_id)) {
							this.resource_collection_ids.push(resource_collection_resource_id)
						}
						// then save the LP; by sending true we'll refresh the window after saving
						this.save_edits(true)
					})

				}).catch(n=>{console.log(n)}).finally(f=>{})
			}, 50)
		},
		update_color(new_color) {
			this.color = new_color
		}
	}
}
</script>

<style lang="scss">
.k-lp-editor-wrapper {
	clear:both;
	// margin:10px;
	// padding-top:15px;
	font-size:16px;
	h3 { padding-left:0!important; }

	.k-lp-editor-top-data {
		background-color:$v-amber-lighten-5;	// :#f08c78;
		padding:15px;
		margin:15px 0;
		border-radius:4px;
	}

	.k-lp-grade-menu {
		max-width:180px;
		margin-left:10px;
	}
	.k-lp-subject-menu {
		max-width:300px;
		margin-left:10px;
	}
	.k-lp-color-menu {
		max-width:150px;
		margin-left:10px;
	}

	.k-lp-color-swatch {
		width:30px;
		height:30px;
		background-color:#999;
		align-self: center;
		margin-left:5px;
		border-radius:8px;
	}

	.k-lp-editor-terms {
		background-color:$v-light-blue-lighten-5;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-lp-editor-term {
		background-color:$v-amber-lighten-5;  // :#92c3e9;
		padding:12px;
		width:calc(25% - 20px);
		margin:10px;
		border-radius:4px;
	}

	.k-lp-editor-units {
		margin-top:20px;
		background-color:$v-purple-lighten-5;
		padding:10px 0px 15px 0;
		border-radius:8px;
	}

	.k-lp-editor-unit {
		padding:10px;
		border:4px solid transparent;
		width:calc(33% - 20px);
		margin:10px;
		border-radius:4px;
		background-color:$v-amber-lighten-5; 	// :#f8cdad;
	}

	.k-lp-editor-resources-header {
		font-size:14px;
		font-weight:bold;
		color:#666;
	}

	.k-lp-editor-standard-resource {
		padding:10px;
		margin:8px 0;
		border-radius:4px;
		background-color:#eee;
	}

	.k-lp-editor-standard-description {
		flex:1 1 auto;
		cursor:pointer;
		overflow:hidden;
		white-space:nowrap;
	}

	.k-lp-editor-standard-text {
		margin-top:8px;
		padding-top:8px;
		border-top:1px solid #ccc;
	}

	.k-lp-editor-resource-creator {
		background-color:#eee;
		margin-top:10px;
		padding:10px;
		border-radius:4px;
	}

	.k-lp-editor-term-importer {
		background-color:#f8cdad;
		padding:15px;
		margin-top:15px;
		border-radius:4px;
	}

	.k-lp-editor-import-box textarea {
		font-size:12px;
		line-height:16px!important;
	}

	.k-lp-editor-description textarea {
		font-size:14px;
		line-height:18px!important;
	}
}

.k-collection-description-froala-wrapper-wrapper {
	.fr-view table td, .fr-view table th { 
		padding:4px;
	}
}
</style>
